import React from 'react';
import fullscreen from '../../assests/images/fullscreen.png'
import 'bootstrap/dist/css/bootstrap.min.css';
const Banner = () => {
  return (

    <div className='my-5 pt-lg-5'>
      <img src={fullscreen} alt="fullscreen" className='fullscreen img-fluid' />
    </div>

  );
};

export default Banner;
