import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

const CartComponent = () => {
  const [cartItems, setCartItems] = useState([
    { product: 'Product 1', price: 29.99, quantity: 2, image: 'https://via.placeholder.com/50' },
    { product: 'Product 2', price: 9.99, quantity: 5, image: 'https://via.placeholder.com/50' },
    { product: 'Product 3', price: 19.99, quantity: 1, image: 'https://via.placeholder.com/52' },
  ]);

  return (
    <div className="container my-5">
      <h2 className="pb-5 text-center">Shopping Cart</h2>
      <table className="table table-bordered text-center ">
        <thead className="">
          <tr className="throwbg">
            <th>Product</th>
            <th>Name</th>
            <th>Price</th>
            <th>Quantity</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {cartItems.map((item, index) => (
            <tr key={index}>
              <td><img src={item.image} alt={item.product} /></td>
              <td>{item.product}</td>
              <td>${item.price.toFixed(2)}</td>
              <td>{item.quantity}</td>
              <td>${(item.price * item.quantity).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="4" className="text- font-weight-bold">Subtotal</td>
            <td className="font-weight-bold">
              ${cartItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2)}
            </td>
          </tr>
          <tr>
            <td colSpan="4" className="text-center font-weight-bold">Total</td>
            <td className="font-weight-bold">
              ${cartItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2)}
            </td>
          </tr>
        </tfoot>
      </table>
      <div className='my-5 text-center pb-5'>
      <Link to="/checkout" className='px-4 py-2 rounded loginbtn text-decoration-none'>Checkout</Link>
      </div>
    </div>
  );
};

export default CartComponent;
