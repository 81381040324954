import React, { useEffect, useRef, useState } from 'react';
import linenew from '../../assests/images/linenew.png';
import sideline from '../../assests/images/sideline.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import f1 from '../../assests/images/founder1.jpg';
import f2 from '../../assests/images/founder2.jpg';
// import f3 from '../../assests/images/founder3.jpg';
// import robinsir from '../../assests/images/robinsir.png'
import r from '../../assests/images/r.jpg'
import axios from 'axios';


const Team = () => {

    const [activeTab, setActiveTab] = useState('pills-team1');
    const [isVisible, setIsVisible] = useState(false);
    const visionRef = useRef(null);
    const missionRef = useRef(null);
    const valuesRef = useRef(null);

    const [ourTeam, setOurTeam] = useState([]);

    const getImages = () => {
        axios.get(process.env.REACT_APP_BACKEND_URL + '/ourteam').then((data) => {
            if (data) {
                setOurTeam(data.data)
            }
        })
    }

    useEffect(() => {
        getImages()
    }, [])



    useEffect(() => {
        const handleScroll = () => {
            if (visionRef.current && missionRef.current && valuesRef.current) {
                const visionPosition = visionRef.current.getBoundingClientRect().top;
                const missionPosition = missionRef.current.getBoundingClientRect().top;
                const valuesPosition = valuesRef.current.getBoundingClientRect().top;
                const screenPosition = window.innerHeight / 1.3; // Adjust as needed

                setIsVisible({
                    vision: visionPosition < screenPosition,
                    mission: missionPosition < screenPosition,
                    values: valuesPosition < screenPosition,
                });
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div className="container my-5">
                <div className="text-center">
                    <h3 className="">Our Milestone</h3>
                    <h5 className="pt-2">Meet the Experts Behind Our Success</h5>
                    <img src={linenew} alt="" className="text-center" width={250} />
                </div>
                <div className="row py-5 px-lg-0 px-3">
                    <div className={`col-lg-8 col-md-12 order-2 order-md-1 ${isVisible.vision ? 'visible' : 'hidden'} slide-in-left`} ref={visionRef}>
                        <h3 className="pb-lg-1 sideheadings">Co Founder</h3>
                        <h5>Mr. Robin Mukherjee</h5>
                        <img src={sideline} alt="sideline" className="img-fluid" />
                        <p className="text-justify custom-line-height pt-3">Clearmood (Habiteliv Endless Purity), founded in 2024, offers high-quality, eco-friendly, customizable water bottles for personal and business use. We focus on sustainability and excellence.</p>
                        <p className="text-justify custom-line-height ">Mr. Robin Mukherjee, with 11 years of IT and academic experience, specializes in e-governance, rural e-business, banking, finance, and SNG contracts. He is widely published and a frequent conference presenter.</p>
                    </div>
                    <div className={`col-lg-4 col-md-12 order-1 order-md-2 ${isVisible.vision ? 'visible' : 'hidden'} slide-in-right`} ref={visionRef}>
                        <div className='cahirmem'>
                            <img src={r} alt="Placeholder" className="chairmenimg" />
                        </div>
                    </div>
                </div>
                <div className="row py-5 order-2 order-md-1 px-lg-0 px-3">
                    <div className={`col-lg-4 col-md-12 ${isVisible.mission ? 'visible' : 'hidden'} slide-in-left`} ref={missionRef}>
                        <div className='cahirmem'>
                            <img src={f2} alt="Placeholder" className="chairmenimg" />
                        </div>
                    </div>
                    <div className={`col-lg-8 col-md-12 ${isVisible.mission ? 'visible' : 'hidden'} slide-in-right`} ref={missionRef}>
                        <h3 className="pb-lg-1 sideheadings">Founder (CEO)</h3>
                        <h5>Mr. Abhay Asthana</h5>
                        <img src={sideline} alt="sideline" className="img-fluid" />
                        <p className="text-justify custom-line-height pt-3">
                            Abhay Asthana is a visionary leader and CEO of our company. With a background in technology, business strategy, finance, and banking, Abhay has been instrumental in driving innovation and growth within the organization.
                        </p>
                        <p className="text-justify custom-line-height">
                            His expertise has helped the company navigate complex market challenges, and his forward-thinking approach continues to shape our strategic direction and success.
                        </p>
                    </div>
                </div>
                <div className="row py-5 px-lg-0 px-3">
                    <div className={`col-lg-8 col-md-12 order-2 order-md-1 ${isVisible.values ? 'visible' : 'hidden'} slide-in-left`} ref={valuesRef}>
                        <h3 className="pb-lg-1 sideheadings">Vice President</h3>
                        <h5>Mr. Pushkar Asthana</h5>
                        <img src={sideline} alt="sideline" className="img-fluid" />
                        <p className="text-justify custom-line-height pt-3">
                            Pushkar Asthana, the Vice President of Habit, is renowned for his deep expertise in both real estate and water bottle manufacturing. Under his guidance, Habit has become a leader in producing sustainable and innovative water bottles, setting new industry standards for quality and purity.
                        </p>
                        <p className="text-justify custom-line-height">
                            Under Pushkar Asthana's leadership, Habit has created eco-friendly products that are popular with green-minded customers. His smart planning has helped Habit grow and become a top company in the industry.
                        </p>
                    </div>
                    <div className={`col-lg-4 col-md-12 order-1 order-md-2 ${isVisible.values ? 'visible' : 'hidden'} slide-in-right`} ref={valuesRef}>
                        <div className='cahirmem'>
                            <img src={f1} alt="Placeholder" className="chairmenimg" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='teambg-banner py-5'>
                <div className="container my-lg-5">
                <div className="text-center">
                    <h3 className="">Our Dedicated Team</h3>             
                    <img src={linenew} alt="" className="text-center pb-lg-5" width={250} />
                </div>
                    <div className="row pt-5 gy-4 px-lg-0 px-3">
                        {ourTeam.length && ourTeam.map((ourteam) => {
                            return <div className="col-lg-4 col-md-6 col-sm-12 mb-4 d-flex align-items-stretch">
                                <div className="our-team h-100">
                                    <div className="pic ">
                                        <img src={`${process.env.REACT_APP_BACKEND}team/${ourteam.image}`} alt={ourteam.name} />
                                    </div>
                                    <div className="team-content">
                                        <h3 className="title">{ourteam.name}</h3>
                                        <h5 className="">{ourteam.role}</h5>
                                        <p className='text-justify px-4 py-3'>{ourteam.description}</p>
                                    </div>                      
                                </div>
                            </div>
                        })}
                    </div>
                    <div className="row d-none">
                        <div className="col-md-12">
                            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link teampills ml-n3 px-3 py-2 font-weight-bold fs-5 ${activeTab === 'pills-team1' ? 'active' : ''}`}
                                        id="pills-team1-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-team1"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-team1"
                                        aria-selected="true"
                                        onClick={() => setActiveTab('pills-team1')}
                                    >
                                        HR Analyst
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link teampills ml-n3 px-3 py-2 font-weight-bold fs-5 ${activeTab === 'pills-team2' ? 'active' : ''}`}
                                        id="pills-team1-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-team2"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-team2"
                                        aria-selected="true"
                                        onClick={() => setActiveTab('pills-team2')}
                                    >
                                        Operation Team
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link teampills mx-n3 px-3 py-2 font-weight-bold fs-5 ${activeTab === 'pills-team3' ? 'active' : ''}`}
                                        id="pills-team2-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-team3"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-team3"
                                        aria-selected="false"
                                        onClick={() => setActiveTab('pills-team3')}
                                    >
                                        Sales Core Team
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className={`tab-pane fade ${activeTab === 'pills-team1' ? 'show active' : ''}`} id="pills-team1" role="tabpanel" aria-labelledby="pills-team1-tab">
                                    {/* hr Team */}
                                    <div className="row pt-5 gy-4">
                                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4 d-flex align-items-stretch">
                                            <div className="our-team h-100">
                                                <div className="pic">
                                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvDeN3aHbzcYhAXmosDsSeE4ZOw8JkFYrOtGeDiwdenqDy8javqObfhZFvia4MrrdT3X0&usqp=CAU" alt="Bernard COVA" />
                                                </div>
                                                <div className="team-content">
                                                    <h3 className="title">Nidhi Gupta</h3>
                                                    <h5 className="">HR Analyst (Pan India)</h5>
                                                    <p className='text-justify px-4 pt-2'>Nidhi Gupta, with over 7 years of experience in IT, brings her expertise to Habit, a leader in water bottle manufacturing. She excels in implementing advanced IT solutions to enhance operational efficiency and ensure compliance, driving innovation within the company.</p>
                                                </div>
                                                <ul className="social">
                                                    <li>
                                                        {/* <a href="#" className="fa fa-envelope"></a> */}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4 d-flex align-items-stretch">
                                            <div className="our-team h-100">
                                                <div className="pic">
                                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvDeN3aHbzcYhAXmosDsSeE4ZOw8JkFYrOtGeDiwdenqDy8javqObfhZFvia4MrrdT3X0&usqp=CAU" alt="Francis Guilbert" />
                                                </div>
                                                <div className="team-content">
                                                    <h3 className="title">Komal Asthana</h3>
                                                    <h5 className="">HR Analyst</h5>
                                                    <p className='text-justify px-4 pt-2'>Komal Asthana serves as an HR Analyst at Habit, specializing in HR operations across India and corporate sales. She plays a key role in aligning HR strategies with business goals, enhancing workforce efficiency, and supporting the company’s growth in the water bottle manufacturing sector.</p>
                                                </div>
                                                <ul className="social">
                                                    <li>
                                                        {/* <a href="#" className="fa fa-envelope"></a> */}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12 mb-4 d-flex align-items-stretch">
                                            <div class="our-team h-100">
                                                <div class="pic">
                                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvDeN3aHbzcYhAXmosDsSeE4ZOw8JkFYrOtGeDiwdenqDy8javqObfhZFvia4MrrdT3X0&usqp=CAU" alt="Anamika Asthana" />
                                                </div>
                                                <div class="team-content">
                                                    <h3 class="title">Anamika Asthana</h3>
                                                    <h5 class="">HR Analyst</h5>
                                                    <p class="text-justify px-4 pt-2">
                                                        Anamika Asthana is an HR Analyst at Habit, where she specializes in enhancing HR operations and supporting corporate strategies across the company. With a focus on talent management and organizational development, Anamika plays a pivotal role in aligning HR practices with Habit’s business goals, contributing to the growth and efficiency of the company in the water bottle manufacturing sector.
                                                    </p>
                                                </div>
                                                <ul class="social">

                                                    <li>
                                                        {/* <a href="#" class="fa fa-envelope"></a>  */}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className={`tab-pane fade ${activeTab === 'pills-team2' ? 'show active' : ''}`} id="pills-team2" role="tabpanel" aria-labelledby="pills-team2-tab">
                                    {/* Operation Team */}
                                    <div className="row pt-5 gy-4">
                                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4 d-flex align-items-stretch">
                                            <div className="our-team h-100">
                                                <div className="pic">
                                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrXh66ZR5-pcHt-LvRPo40zHoCCnzJa_hqzBaO6FSm5pE9j7FMUcD26sIgdOdh-oUILn8&usqp=CAU" alt="Francis Guilbert" />
                                                </div>
                                                <div className="team-content">
                                                    <h3 className="title">Dharmendra Kumar</h3>
                                                    <h5 className="">Operation & Financial Head</h5>
                                                    <p className='text-justify px-4 pt-2'>With over 5 years in financial services and business development, Mr. Dharmendra Kumar is the Operation & Financial Head at Habit. He specializes in profit center management, financial planning, and operational efficiency, driving growth in the water bottle manufacturing industry.</p>
                                                </div>
                                                <ul className="social">
                                                    <li>
                                                        {/* <a href="#" className="fa fa-envelope"></a> */}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`tab-pane fade ${activeTab === 'pills-team3' ? 'show active' : ''}`} id="pills-team3" role="tabpanel" aria-labelledby="pills-team3-tab">
                                    {/* Sales Core Team */}
                                    <div className="row gy-4 pt-5">
                                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4 d-flex align-items-stretch">
                                            <div className="our-team h-100">
                                                <div className="pic">
                                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrXh66ZR5-pcHt-LvRPo40zHoCCnzJa_hqzBaO6FSm5pE9j7FMUcD26sIgdOdh-oUILn8&usqp=CAU" alt="Bishwajeet Banerjee" />
                                                </div>
                                                <div className="team-content">
                                                    <h3 className="title">Mr. Bishwajeet Banerjee</h3>
                                                    <h5 className="">U.P Zonal Head</h5>
                                                    <p className='text-justify px-4 pt-2'>Mr. Bishwajeet Banerjee is the Vice President at Habit with over 20 years of senior-level experience across telecom, IT, automobile spares, consumer durables, and real estate. His expertise includes establishing successful businesses and enhancing company brands through strategic engagements.</p>
                                                </div>
                                                <ul className="social">
                                                    {/* Add social links here */}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4 d-flex align-items-stretch">
                                            <div className="our-team h-100">
                                                <div className="pic">
                                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvDeN3aHbzcYhAXmosDsSeE4ZOw8JkFYrOtGeDiwdenqDy8javqObfhZFvia4MrrdT3X0&usqp=CAU" alt="Shriti Ghosal" />
                                                </div>
                                                <div className="team-content">
                                                    <h3 className="title">Shriti Ghosal</h3>
                                                    <h5 className="">Corporate Sales Analyst</h5>
                                                    <p className='text-justify px-4 pt-2'>Shriti Ghosal is a Corporate Sales Analyst specializing in driving sales strategies and fostering client relationships. With a robust background in sales analytics and strategy implementation, Shriti leverages her skills to optimize business growth and meet client needs effectively.</p>
                                                </div>
                                                <ul className="social">
                                                    {/* Add social links here */}
                                                </ul>
                                            </div>
                                        </div>
                                        {/* Add more team members here */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Team;
