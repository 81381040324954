
import React, { useEffect } from 'react';
import { FaCircleArrowRight } from "react-icons/fa6";
import { faQuoteLeft,faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const Bestfe2 = () => {
  useEffect(() => {
    const elements = document.querySelectorAll('.slide-in-top, .slide-in-bottom');
    elements.forEach(element => {
      element.style.opacity = '0';
      element.style.transform = 'translateY(50px)';
    });

    const slideIn = () => {
      elements.forEach(element => {
        if (isElementInViewport(element)) {
          element.style.opacity = '1';
          element.style.transform = 'translateY(0)';
        }
      });
    };

    const isElementInViewport = (el) => {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    window.addEventListener('scroll', slideIn);
    slideIn();

    return () => {
      window.removeEventListener('scroll', slideIn);
    };
  }, []);

  return (
    <div>
      <div className="my-5">
        <div className="bg-banner px-4 text-center">
          <div className="py-lg-5">
            <h2 className=" slide-in-bottom bestfe2text text-white pt-5">
            <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon" /> Sip sustainably, love the planet with every drop. <br />
              Join the movement towards a greener future, one bottle at a time.<FontAwesomeIcon icon={faQuoteRight} className="quote-icon" />
            </h2>
            <div className="py-lg-5 py-4 px-3 slide-in-bottom">
              <Link to='/contact' className="text-center font-weight-bold text-decoration-none bannerbtn">
                Contact Us <FaCircleArrowRight className='ml-2 ' />
              </Link>
            </div>
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bestfe2;
