import React from 'react'
import Careerhero from '../../components/career/Careerhero'
import Careerinfo from '../../components/career/Careerinfo'
import Careerform from '../../components/career/Careerform'
import Qualificationneed from '../../components/career/Qualificationneed'
const Carrier = () => {
  return (
    <div>
      <Careerhero />   
      <Careerinfo />
      <Careerform />
      <Qualificationneed />
    </div>
  )
}

export default Carrier
