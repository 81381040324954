import React from 'react'
import Teamhero from '../../components/team/Teamhero'
import Team from '../../components/team/Team'
export const Ourcoreteam = () => {
  return (
    <>
     <Teamhero />
     <Team />
    </>
   
  )
}
