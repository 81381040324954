import React from 'react'
import { Link } from 'react-router-dom'
import Registercomponent from '../../components/usercomponent/Registercomponent'
const Register = () => {
  return (
    <div>
      <nav aria-label="breadcrumb  ">
        <ol className="breadcrumb bg-white">
          <li className="breadcrumb-item"><Link to="/home" className='text-dark text-decoration-none'>Home</Link></li>
          <li className="breadcrumb-item active activepage" aria-current="page">Register</li>
        </ol>
      </nav>
      <Registercomponent />
    </div>
  )
}

export default Register
