import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaCcVisa, FaCcMastercard, FaCcAmex } from 'react-icons/fa';
const CheckoutComponent = () => {
    const [formData, setFormData] = useState({
        country: '',
        full_name: '',
        address: '',
        city: '',
        state: '',
        zip_code: '',
        phone_number: '',
        email_address: '',
        paymentMethod: 'creditCard',
        CreditCardType: '',
        card_number: '',
        card_code: '',
        exp_month: '',
        exp_year: ''
    });
    const [showPopup, setShowPopup] = useState(false);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setShowPopup(true);
    };
    return (
        <div className="container my-5">
            <h3 className='text-center pb-5'>Checkout</h3>
            <div className="row">
                <div className="col-lg-6">
                    <form onSubmit={handleSubmit}>
                        <div className="card mb-4">
                            <div className="cardheader">
                                <h5>Shipping Address</h5>
                            </div>
                            <div className="card-body">
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="full_name">Full Name:</label>
                                        <input
                                            type="text"
                                            name="full_name"
                                            className="form-control"
                                            value={formData.full_name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="address">House No/Landmark:</label>
                                    <input
                                        type="text"
                                        name="address"
                                        className="form-control"
                                        value={formData.address}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <label htmlFor="city">City:</label>
                                        <input
                                            type="text"
                                            name="city"
                                            className="form-control"
                                            value={formData.city}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="state">State:</label>
                                        <input
                                            type="text"
                                            name="state"
                                            className="form-control"
                                            value={formData.state}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="zip_code">Zip / Postal Code:</label>
                                        <input
                                            type="text"
                                            name="zip_code"
                                            className="form-control"
                                            value={formData.zip_code}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="phone_number">Phone Number:</label>
                                        <input
                                            type="text"
                                            name="phone_number"
                                            className="form-control"
                                            value={formData.phone_number}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="email_address">Email Address:</label>
                                        <input
                                            type="email"
                                            name="email_address"
                                            className="form-control"
                                            value={formData.email_address}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="cardheader">
                                <span><i className="glyphicon glyphicon-lock"></i></span> Secure Payment
                            </div>
                            <div className="card-body">
                                <div className="form-group">
                                    <label>Payment Method:</label>
                                    <div className="form-check">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            id="creditCard"
                                            name="paymentMethod"
                                            value="creditCard"
                                            checked={formData.paymentMethod === "creditCard"}
                                            onChange={handleChange}
                                        />
                                        <label className="form-check-label" htmlFor="creditCard">Credit Card</label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            id="debitCard"
                                            name="paymentMethod"
                                            value="debitCard"
                                            checked={formData.paymentMethod === "debitCard"}
                                            onChange={handleChange}
                                        />
                                        <label className="form-check-label" htmlFor="debitCard">Debit Card</label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            id="cashOnDelivery"
                                            name="paymentMethod"
                                            value="cashOnDelivery"
                                            checked={formData.paymentMethod === "cashOnDelivery"}
                                            onChange={handleChange}
                                        />
                                        <label className="form-check-label" htmlFor="cashOnDelivery">Cash on Delivery</label>
                                    </div>
                                </div>

                                {(formData.paymentMethod === "creditCard" || formData.paymentMethod === "debitCard") && (
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="CreditCardType">Card Type:</label>
                                            <select
                                                id="CreditCardType"
                                                name="CreditCardType"
                                                className="form-control"
                                                value={formData.CreditCardType}
                                                onChange={handleChange}
                                            >
                                                <option value="5">Visa</option>
                                                <option value="6">MasterCard</option>
                                                <option value="7">American Express</option>
                                                <option value="8">Discover</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="card_number">Card Number:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="card_number"
                                                value={formData.card_number}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="card_code">Card CVV:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="card_code"
                                                value={formData.card_code}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="exp_month">Expiration Month:</label>
                                                <select
                                                    className="form-control"
                                                    name="exp_month"
                                                    value={formData.exp_month}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Month</option>
                                                    {[...Array(12).keys()].map(month => (
                                                        <option key={month + 1} value={String(month + 1).padStart(2, '0')}>
                                                            {String(month + 1).padStart(2, '0')}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="exp_year">Expiration Year:</label>
                                                <select
                                                    className="form-control"
                                                    name="exp_year"
                                                    value={formData.exp_year}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Year</option>
                                                    {[...Array(10).keys()].map(year => (
                                                        <option key={year + 2021} value={year + 2021}>
                                                            {year + 2021}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <span>Pay secure using your credit or debit card.</span>
                                            <ul className="list-inline">
                                                <li className="list-inline-item"><FaCcVisa size={24} /></li>
                                                <li className="list-inline-item"><FaCcMastercard size={24} /></li>
                                                <li className="list-inline-item"><FaCcAmex size={24} /></li>
                                            </ul>
                                        </div>
                                    </>
                                )}

                                {formData.paymentMethod === "cashOnDelivery" && (
                                    <div className="form-group">
                                        <p>You have selected Cash on Delivery. You will pay in cash when your order is delivered.</p>
                                    </div>
                                )}
                                <div className="form-group">
                                    <button type="submit" className="py-1 loginbtn btn-block">Place Order</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-lg-6">
                    <div className="card mb-4">
                        <div className="cardheader">
                            <h5>Review Order <span><Link to="/cart" className="float-right text-dark text-decoration-none">Edit Cart</Link></span></h5>
                        </div>
                        <div className="card-body">
                            {[1, 1, 2].map((qty, index) => (
                                <div key={index} className="d-flex mb-3">
                                    <div className="mr-3">
                                        <img className="img-fluid" src="//c1.staticflickr.com/1/466/19681864394_c332ae87df_t.jpg" alt="Product" />
                                    </div>
                                    <div className="flex-grow-1">
                                        <div>Product name</div>
                                        <small>Quantity: {qty}</small>
                                    </div>
                                    <div className="text-right">
                                        <h6>${25 * qty}.00</h6>
                                    </div>
                                </div>
                            ))}
                            <div className="border-top pt-3">
                                <div className="d-flex justify-content-between">
                                    <strong>Subtotal</strong>
                                    <span>$200.00</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <small>Shipping</small>
                                    <span>-</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <strong>Order Total</strong>
                                    <span>$150.00</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* REVIEW ORDER END */}
                </div>
            </div>

            {showPopup && (
                <div className="modal fade show" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Thank You!</h5>
                                <button type="button" className="close" onClick={() => setShowPopup(false)}>
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Your order has been placed successfully.</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowPopup(false)}>Close</button>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show" onClick={() => setShowPopup(false)}></div>
                </div>
            )}
        </div>
    );
};

export default CheckoutComponent;
