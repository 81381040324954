import React from 'react';
import { Link } from 'react-router-dom';
const Notfound = () => {
  return (
    <div className="not-found-container">
      <h1 className="not-found-heading">404</h1>
      <h2 className="not-found-subheading">Page Not Found</h2>
      <p className="not-found-text">Sorry, the page you are looking for does not exist.</p>
      <Link to="/" className="not-found-button text-decoration-none">Go to Home</Link>
    </div>
  );
};

export default Notfound;
