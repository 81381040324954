import React from 'react';
import linenew from '../../assests/images/linenew.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OwlCarousel from 'react-owl-carousel';
import { Card } from 'react-bootstrap';
const Feedback = () => {
  return (
    <div className="container my-5">
      <div className="section-title">
        <div className="text-center pb-5">
          <h3 className="pt-lg-5">Happy Clients</h3>
          <h5 className="pt-2">What Our Clients Say About Us</h5>
          <img src={linenew} alt="line decoration" className="text-center" width={250} />
        </div>
      </div>
      <div>
        <OwlCarousel className="owl-theme" loop margin={10} nav items={1}>
          <div className="item feedbackitems">
            <div className="d-flex flex-column flex-md-row text-center ">
              <Card className="h-100 border-0">
                <Card.Body>
                  <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon-feedback" />
                  <Card.Text className="text-center feedtext">
                    Using Habit water bottles has made staying hydrated easy and enjoyable. The design is sleek and convenient for everyday use. Highly recommended!
                  </Card.Text>
                  <div className="rating text-center py-3 text-warning">
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  <Card.Title className="text-uppercase mt-3 text-center">Rahul Sharma</Card.Title>
                </Card.Body>
              </Card>
              <Card className="h-100 border-0">
                <Card.Body>
                  <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon-feedback" />
                  <Card.Text className="text-center feedtext">
                    Habit's water bottles keep my drinks cold for hours. The quality and durability are unmatched. Fantastic product!
                  </Card.Text>
                  <div className="rating text-center py-3 text-warning">
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  <Card.Title className="text-uppercase mt-3 text-center">Alisha Patel</Card.Title>
                </Card.Body>
              </Card>
              <Card className="h-100 border-0">
                <Card.Body>
                  <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon-feedback" />
                  <Card.Text className="text-center feedtext">
                    I've tried many water bottles, but Habit stands out. It's easy to clean and perfect for my active lifestyle. Very satisfied!
                  </Card.Text>
                  <div className="rating text-center py-3 text-warning">
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  <Card.Title className="text-uppercase mt-3 text-center">Kunal Mehta</Card.Title>
                </Card.Body>
              </Card>
            </div>
          </div>
          <div className="item">
            <div className="d-flex flex-column flex-md-row text-center">
              <Card className="h-100 border-0">
                <Card.Body>
                  <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon-feedback" />
                  <Card.Text className="text-center feedtext">
                    We love the design and efficiency of Habit water bottles. Our family uses them daily, and they never disappoint!
                  </Card.Text>
                  <div className="rating text-center py-3 text-warning">
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  <Card.Title className="text-uppercase mt-3 text-center">Priya Verma</Card.Title>
                </Card.Body>
              </Card>
              <Card className="h-100 border-0">
                <Card.Body>
                  <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon-feedback" />
                  <Card.Text className="text-center feedtext">
                    The water quality from Habit bottles is exceptional. We are very pleased with our purchase and highly recommend them!
                  </Card.Text>
                  <div className="rating text-center py-3 text-warning">
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  <Card.Title className="text-uppercase mt-3 text-center">Ananya Singh</Card.Title>
                </Card.Body>
              </Card>
              <Card className="h-100 border-0">
                <Card.Body>
                  <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon-feedback" />
                  <Card.Text className="text-center feedtext">
                    Habit has the best water bottles on the market. Great taste and excellent insulation!
                  </Card.Text>
                  <div className="rating text-center py-3 text-warning">
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  <Card.Title className="text-uppercase mt-3 text-center">Vikram Kumar</Card.Title>
                </Card.Body>
              </Card>
            </div>
          </div>
        </OwlCarousel>
      </div>
    </div>
  );
};

export default Feedback;
