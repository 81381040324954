import React, { useEffect, useState } from 'react'
import Slider from '../../components/slider/Slider'
import Services from '../../components/bestfe/Services'
import Productcompoent from '../../components/products/Productcomponent'
import Feedback from '../../components/feedback/Feedback'
//  import Bestfe1 from '../../components/bestfe/Bestfe1'
import Bestfe from '../../components/bestfe/Bestfe'
import Bestfe2 from '../../components/bestfe/Bestfe2'
import Faq from '../../components/aboutcompany/Faq'
import Features from '../../components/aboutcompany/Features';
import axios from 'axios' 
import Bestfe3 from '../../components/bestfe/Bestfe3'
import Trust from '../../components/bestfe/Trust'
import Banner from '../../components/banner/Banner'
import Banner_Video from '../../components/banner/Banner_Video'

const Home = () => {
  const [homeData, setHomeData] = useState({})
  const getHome = () =>{
    axios.get(process.env.REACT_APP_BACKEND_URL + '/home').then((data)=>{
        if(data){
          setHomeData(data.data)
        }
    })
}
console.log(homeData)
useEffect(()=>{
  getHome()
}, [])
  return (
    <>
      <Slider images={homeData.banner_data} />
      {/* <Bestfe1  images={homeData.banner_data}/>      */}
      <Services />
      <Banner />    
      <Productcompoent product={homeData.product_data} />  
      {/* <Banner_Video />     */}
      <Bestfe3 />  
        
      <Features />  
      <Bestfe2 />
      <Faq />
      <Bestfe /> 
      <Trust />
      <Feedback />
    </>
  )
}

export default Home
