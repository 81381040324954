import React from 'react'
const Gmap = () => {
    return (
        <div>
        <div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29330.273908525483!2d79.93865749211804!3d23.232741593598664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3981af9def27ff77%3A0xc1a3a967c40f60fd!2sMaharajpur%2C%20Jabalpur%2C%20Madhya%20Pradesh%20482004!5e0!3m2!1sen!2sin!4v1717656702684!5m2!1sen!2sin" className='border-0 contactmap' allowfullscreen="" width={1720} height={400} ></iframe>        </div>
    </div >
  )
}

export default Gmap
