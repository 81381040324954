import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import product from '../../assests/images/habitbottle.png';
import { Link } from 'react-router-dom';
import linenew from '../../assests/images/linenew.png';

const Productcompoent = (props) => {
  const [products, setProduct] = useState([]);

  useEffect(() => {
    setProduct(props.product);
  }, [props.product]);

  return (
    <div className='container my-5'>
      <div className='text-center pb-5'>
        <h3 className="whyh1 pb-1">Our Products</h3>
        <h5>Discover our extensive range of products.</h5>
        <img src={linenew} alt="" className='text-center' width={250} />
      </div>
      <div className="row justify-content-center">
        {products && products.map(product => (
          <div key={product.id} className="col-md-4 mb-5 d-flex align-items-center justify-content-center">
            <div className="product text-center product-card">
              <div className="position-relative">
                <img className="product-image-fixed w-100 p-4" 
                     src={`${process.env.REACT_APP_BACKEND}uploads/${product.image}`} 
                     alt={product.name} />
              </div>
              <div className="card-body pcardbody">
                <h5 className="fs-5 ">{product.name}</h5>
                <p className="text-success font-weight-bold fs-5">{product.offer}</p>
                <h6 className="fw-bolder fs-5">Rs {product.sprice}</h6>
                <div className="mt-3">
                  <Link to={`/productdetails/${product.id}`} className="btn text-white px-5 font-weight-bold">More Info</Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className='text-center pb-5'>
        <Link to='/product' className="text-white border-0 viewmore px-5 text-decoration-none">View More</Link>
      </div>
    </div>
  );
};

export default Productcompoent;
