import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Similarproduct = () => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        getProduct();
    }, []);

    const getProduct = () => {
        axios.get(process.env.REACT_APP_BACKEND_URL + 'product/random').then((response) => {
            if (response.data) {
                setProducts(response.data);
            }
        }).catch((error) => {
            console.error('Error fetching products:', error);
        });
    };

    return (
        <section className="pt-5 pb-5">
            <div className="container">
                <h3 className="mb-5 similarp">Similar Products</h3>
                <div className="row">
                    {products && products.map((product, index) => (
                        <div className="col-md-4 mb-3" key={index}>
                            <div className="position-relative">
                            <img className="product-image w-100 productcardbg productcard p-4"   src={`${process.env.REACT_APP_BACKEND}uploads/${product.image}`} alt='product' />
                            </div>
                            <div className="card-body text-center">
                                <h4 className="fs-5">{product.name}</h4>
                                <p className="text-success font-weight-bold fs-5">{product.offer}</p>
                                <h6 className="fw-bolder fs-4">{product.sprice} RS</h6>
                                <div className="mt-3">
                                    <Link to={`/productdetails/${product.id}`} className="btn text-white px-5 font-weight-bold">More Info</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Similarproduct;
