import React from 'react'
import Allproducts from '../../components/products/Allproducts'
import Producthero from '../../components/products/Producthero'

const Product = () => {
  return (
    <div>
     <Producthero /> 
      <Allproducts />
    </div>
  )
}

export default Product
