import React, { useEffect, useState } from 'react';
import product from '../../assests/images/habitbottle.png';
import linenew from '../../assests/images/linenew.png'
import axios from 'axios';
const Gallery = () => {
  const images_data = [
    {
      id:1,
      imgUrl: product,
    },
    {
      id:2,
      imgUrl: product,
    },
    {
      id:3,
      imgUrl: product,
    },
    {
      id:4,
      imgUrl: product,
    },
    {
      id:5,
      imgUrl: product,
    },
    {
      id:6,
      imgUrl: product,
    },
    {
      id:7,
      imgUrl: product,
    },
    {
      id:8,
      imgUrl: product,
    },
    {
      id:9,
      imgUrl: product,
    },
    {
      id:10,
      imgUrl: product,
    },
    {
      id:11,
      imgUrl: product,
    },
    {
      id:12,
      imgUrl: product,
    },
  ];
  const itemsPerPage = 18;
  const [currentPage, setCurrentPage] = useState(1);
  const [images, setImages] = useState(images_data);

  const getImages = () =>{
    axios.get(process.env.REACT_APP_BACKEND_URL + '/gallery').then((data)=>{
        if(data){
          setImages(data.data)
        }
    })
}

useEffect(()=>{
  getImages()
},[])

  const handleClick = (newPage) => {
    setCurrentPage(newPage);
  };
  const handlePrevious = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };
  const handleNext = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const selectedImages = images.slice(startIndex, startIndex + itemsPerPage);

  const totalPages = Math.ceil(images.length / itemsPerPage);
  return (
    <div className='container my-5'>
      <div className='text-center pb-5'>
             <h3 className="">Our Gallery </h3>
             <h5>Explore our gallery</h5>
                <img src={linenew} alt="" className='text-center' width={250} />
            </div>
      <div className="row">
        {selectedImages.map((images, index) => (
          <div key={index} className="col-lg-4 col-md-12 gallery-col">
            <div className="gallery-item">
              <img
                src={`${process.env.REACT_APP_BACKEND}gallery/${images.image}`}
                className="w-100  gallery-img p-3"
                alt={`Gallery item ${index + 1}`}
              />
              {/* <button className="shop-now">Shop Now</button> */}
            </div>
          </div>
        ))}
      </div>
      <div className="pagination">
        <button
          onClick={handlePrevious}
          className="page-button"
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
          <button
            key={page}
            onClick={() => handleClick(page)}
            className={`page-button ${page === currentPage ? 'active' : ''}`}
          >
            {page}
          </button>
        ))}
        <button
          onClick={handleNext}
          className="page-button"
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default Gallery;
