import React, { useEffect, useRef } from 'react';
import { MdSupportAgent } from "react-icons/md";
import { BsSpeedometer } from "react-icons/bs";
import { TbRouteSquare } from "react-icons/tb";

const Trust = () => {
  const fastDeliveryRef = useRef(null);
  const supportRef = useRef(null);
  const satisfactionRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      animateIfVisible(fastDeliveryRef);
      animateIfVisible(supportRef);
      animateIfVisible(satisfactionRef);
    };

    const animateIfVisible = (ref) => {
      if (ref.current) {
        const elementPosition = ref.current.getBoundingClientRect().top;
        const screenPosition = window.innerHeight / 1.3;

        if (elementPosition < screenPosition) {
          ref.current.classList.add('visible');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); 

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='besfrerow'>
      <div className='container '>
        <div className='row pb-5'>
          <div className='col-lg-4 col-md-12 slide-in-left bestfecol' ref={fastDeliveryRef}>
            <div className='row bestferow'>
              <div className='col-lg-3 '>
                <MdSupportAgent className='bestfeicon' />
              </div>
              <div className='col-lg-9 '>
                <h5>Efficiency</h5>
                <p className='fs-5 bestfetext'>Swift delivery of water to your doorstep.</p>
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-md-12 slide-in-bottom bestfecol' ref={supportRef}>
            <div className='row bestferow'>
              <div className='col-lg-3 '>
                <BsSpeedometer className='bestfeicon' />
              </div>
              <div className='col-lg-9 col-md-12'>
                <h5>Accessibility</h5>
                <p className='fs-5 bestfetext'>Convenient access to water delivery across the city.</p>
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-md-12 slide-in-right bestfecol' ref={satisfactionRef}>
            <div className='row bestferow'>
              <div className='col-lg-3 col-md-12'>
                <TbRouteSquare className='bestfeicon' />
              </div>
              <div className='col-lg-9 col-md-12'>
                <h5>Coverage</h5>
                <p className='fs-5 bestfetext'>Comprehensive delivery network covering all major city routes.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trust;
