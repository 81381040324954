import React, { useEffect, useRef } from 'react';
import linenew from '../../assests/images/linenew.png'
const Faq = () => {
  const faqRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      {
        threshold: 0.1, 
      }
    );

    if (faqRef.current) {
      observer.observe(faqRef.current);
    }
    return () => {
      if (faqRef.current) {
        observer.unobserve(faqRef.current);
      }
    };
  }, []);
  return (
    <div className='container my-5'>
      <div className='text-center pb-5'>
        <h3 className='pt-lg-5'>FAQs</h3>
        <h5 className='pt-2'>Quick Answers to Your Questions</h5>
        <img src={linenew} alt="Line Image" className='text-center' width={250} />
      </div>
      <div className="row justify-content-center">
        <div className="col-11 col-xl-10 slide-in-bottom" ref={faqRef}>
          <div className="accordion accordion-flush" id="faqWater">
            <div className="accordion-item bg-transparent border-top border-bottom py-3">
              <h2 className="accordion-header" id="faqWaterHeading1">
                <button className="accordion-button collapsed bg-transparent fw-bold shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#faqWaterCollapse1" aria-expanded="false" aria-controls="faqWaterCollapse1">
                  Why is it important to have a steady water supply?
                </button>
              </h2>
              <div id="faqWaterCollapse1" className="accordion-collapse collapse" aria-labelledby="faqWaterHeading1">
                <div className="accordion-body">
                  <p>Having a steady water supply ensures that you always have clean and safe water to drink and use. It helps keep you healthy and prevents diseases.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item bg-transparent border-bottom py-3">
              <h2 className="accordion-header" id="faqWaterHeading2">
                <button className="accordion-button collapsed bg-transparent fw-bold shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#faqWaterCollapse2" aria-expanded="false" aria-controls="faqWaterCollapse2">
                  How do we make sure the water is clean?
                </button>
              </h2>
              <div id="faqWaterCollapse2" className="accordion-collapse collapse" aria-labelledby="faqWaterHeading2">
                <div className="accordion-body">
                  <p>We use advanced methods like filtration, reverse osmosis, and UV treatment to remove all impurities and germs from the water, making it safe to drink.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item bg-transparent border-bottom py-3">
              <h2 className="accordion-header" id="faqWaterHeading3">
                <button className="accordion-button collapsed bg-transparent fw-bold shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#faqWaterCollapse3" aria-expanded="false" aria-controls="faqWaterCollapse3">
                  What is packaged drinking water?
                </button>
              </h2>
              <div id="faqWaterCollapse3" className="accordion-collapse collapse" aria-labelledby="faqWaterHeading3">
                <div className="accordion-body">
                  <p>Packaged drinking water is water that has been purified and sealed in bottles for you to drink. It is tested and safe for consumption.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item bg-transparent border-bottom py-3">
              <h2 className="accordion-header" id="faqWaterHeading4">
                <button className="accordion-button collapsed bg-transparent fw-bold shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#faqWaterCollapse4" aria-expanded="false" aria-controls="faqWaterCollapse4">
                  Is it safe to drink water from plastic bottles?
                </button>
              </h2>
              <div id="faqWaterCollapse4" className="accordion-collapse collapse" aria-labelledby="faqWaterHeading4">
                <div className="accordion-body">
                  <p>Yes, it is safe. Our plastic bottles are made from food-grade materials and are free from harmful chemicals.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item bg-transparent border-bottom py-3">
              <h2 className="accordion-header" id="faqWaterHeading5">
                <button className="accordion-button collapsed bg-transparent fw-bold shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#faqWaterCollapse5" aria-expanded="false" aria-controls="faqWaterCollapse5">
                  Why does bottled water taste different?
                </button>
              </h2>
              <div id="faqWaterCollapse5" className="accordion-collapse collapse" aria-labelledby="faqWaterHeading5">
                <div className="accordion-body">
                  <p>Bottled water can taste different because of the minerals in the water and the way it is purified. These factors give each brand a unique taste.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item bg-transparent border-bottom py-3">
              <h2 className="accordion-header" id="faqWaterHeading6">
                <button className="accordion-button collapsed bg-transparent fw-bold shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#faqWaterCollapse6" aria-expanded="false" aria-controls="faqWaterCollapse6">
                  How much does bottled water cost?
                </button>
              </h2>
              <div id="faqWaterCollapse6" className="accordion-collapse collapse" aria-labelledby="faqWaterHeading6">
                <div className="accordion-body">
                  <p>The price of bottled water varies. Generally, it depending on the size of the bottle.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
