import React, { useEffect, useState } from 'react';
import { FaRegHandshake } from "react-icons/fa";
import { BsPersonHeart } from "react-icons/bs";
import { IoPersonSharp } from "react-icons/io5";
import { MdPerson3 } from "react-icons/md";
import 'bootstrap/dist/css/bootstrap.min.css';
const Counter = ({ icon, target, duration, text }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const end = target;
    const incrementTime = Math.abs(Math.floor(duration / end));
    const timer = setInterval(() => {
      start += 1;
      setCount(start);
      if (start === end) {
        clearInterval(timer);
      }
    }, incrementTime);
  }, [target, duration]);

  const renderIcon = () => {
    switch (icon) {
      case 'code':
        return <FaRegHandshake  className="counter-icon" />;
      case 'coffee':
        return <BsPersonHeart className="counter-icon" />;
      case 'lightbulb':
        return <IoPersonSharp  className="counter-icon" />;
      case 'employees':
          return <MdPerson3  className="counter-icon" />;
      default:
        return null;
    }
  };

  return (
    <div className="counter text-center">
      {renderIcon()}
      <h2 className="timer count-title count-number">{count}</h2>
      <p className="count-text">{text}</p>
    </div>
  );
};

export default Counter;
