import React, { useEffect, useState } from 'react'
import s1 from '../../assests/images/slide1.jpeg'
import s2 from '../../assests/images/slide2.jpeg'
import s3 from '../../assests/images/slide4.jpeg'
import 'bootstrap/dist/css/bootstrap.min.css';
const Slider = (props) => {
  const [images, setImages] = useState([])

  useEffect(()=>{
    setImages(props.images)
    console.log(props.images)
  }, [props.images])
  return (
    <>
      <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner pt-4">
          {images && images.length && images.map((item, index) => {
            return <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
              <img className="d-block w-100 carousel-image" src={`${process.env.REACT_APP_BACKEND}banner/${item.image}`} alt="First slide" />
            </div>
          })}
        </div>
        <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>
      </div>

    </>
  )
}
export default Slider;
