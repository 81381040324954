import React from 'react'
import Gallery from '../../components/gallery/Gallery'
import Galleryhero from '../../components/gallery/Galleryhero'
const Product_Gallery = () => {
  return (
    <div>  
      <Galleryhero />   
      <Gallery />
    </div>
  )
}

export default Product_Gallery
