import React from 'react'
import {Link} from 'react-router-dom'
import Cartcomponent from '../../components/cart/Cartcomponent'
const Cart = () => {
    return (
        <div>
            <nav aria-label="breadcrumb  ">
                <ol className="breadcrumb bg-white">
                    <li className="breadcrumb-item"><Link to="/" className='text-dark text-decoration-none'>Home</Link></li>
                    <li className="breadcrumb-item "> <Link to="/about" className='text-dark text-decoration-none' >Product</Link></li>
                    <li className="breadcrumb-item active activepage" aria-current="page">Cart</li>
                </ol>
            </nav>
            <Cartcomponent />
        </div>
    )
}

export default Cart
