import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
const Enquiryform = () => {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    quantity: '',
    message: '',
    address:'',
    product:''
  });
  const [errors, setErrors] = useState({});
  useEffect(() => {
    const forms = document.querySelectorAll('.needs-validation');
    Array.prototype.slice.call(forms).forEach((form) => {
      form.addEventListener('submit', (event) => {
        if (!form.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const validate = () => {
    const errors = {};
    if (!formData.name) {
        errors.name = "Name is required";
    }
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(parseInt(formData.mobile))) {
        errors.mobile = "Phone number must be 10 digits";
    }
    if (!formData.message) {
        errors.message = "Message is required";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
};
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      console.log('Form data:', formData);
      const rawResponse = await fetch('http://localhost:8000/api/v1/enquire/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      alert('Form submitted successfully!');
      setFormData({
        name: '',
        mobile: '',
        quantity: '',
        message: '',
        address:'',
        product:''
      });
    }
  };
  return (
    <div className="container mt-5 pt-5">
      <h3 className="text-center mb-4">Enquiry Form</h3>
      <form className="enquiry-form p-4 mb-5 rounded  needs-validation" noValidate>
        <div className="row">
          <div className='col-sm-6'>
            <div className="form-group mb-3">
              <label htmlFor="name">Full Name</label>
              <input type="text" className="form-control" id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required />
              <div className="invalid-feedback">Please enter your name.</div>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="quantity">Quantity</label>
              <input type='text'  className="form-control" id="quantity" name="quantity" value={formData.quantity}
                onChange={handleChange} required />
              <div className="invalid-feedback">Please select a quantity.</div>
            </div>
          </div>
          <div className='col-sm-6'>
            <div className="form-group mb-3">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                required
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                value={formData.email}
                onChange={handleChange}
              />
              <div className="invalid-feedback">Please enter a valid email address.</div>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="product">Product</label>
              <select className="form-control" id="product" name="product"
                value={formData.product}
                onChange={handleChange} required>
                <option>Select a product</option>
                <option value="250">250ml</option>
                <option value="300">300ml</option>
                <option value="500">500ml</option>
                <option value="1000">1000ml</option>
              </select>
              <div className="invalid-feedback">Please select a product.</div>
            </div>
          </div>
          <div className='col-sm-6'>
            <div className="form-group mb-3">
              <label htmlFor="phone">Phone</label>
              <input
                type="tel"
                className="form-control"
                id="phone"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                required
                pattern="^\+?[1-9]\d{1,14}$"
              />
              <div className="invalid-feedback">Please enter a valid phone number.</div>
            </div>
          </div>
          <div className='col-sm-6'>
            <div className="form-group mb-3">
              <label htmlFor="address">Address</label>
              <input type="text" className="form-control" id="address" name="address" value={formData.address}
                onChange={handleChange}
                required />
              <div className="invalid-feedback">Please enter your address.</div>
            </div>
          </div>
          <div className='col-12'>
            <div className="form-group mb-4">
              <label htmlFor="message">Message</label>
              <textarea className="form-control" id="message" name="message" rows="4" value={formData.message}
                onChange={handleChange} required></textarea>
              <div className="invalid-feedback">Please enter your message.</div>
            </div>
          </div>
        </div>
        <div className='py-2 text-center'>
          <button type="button" className="px-4 border-0 p-2 rounded submitbtn text-center" onClick={handleSubmit}>Submit</button>
        </div>
      </form>
    </div>
  );
};

export default Enquiryform;
