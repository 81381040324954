import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
const Head = () => {
    return (
        <div>
            <div className="common-head">
                <div className="py-5 text-center">
                    <div className="py-5">
                        <h3 className='mb-2 text-dark'>About Us</h3>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center bg-transparent">
                                <li className="breadcrumb-item">
                                    <Link to="/" className='text-dark text-decoration-none'>Home</Link>
                                </li>
                                <li className="breadcrumb-item active activepage" aria-current="page">About</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Head;
