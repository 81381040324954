import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { FaUser, FaLock, FaEnvelope, FaPhoneAlt } from 'react-icons/fa';

const RegisterComponent = () => {
  const [formData, setFormData] = useState({ username: '', email: '', password: '', mobile: '' });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.username) tempErrors.username = "Username is required";
    if (!formData.email) tempErrors.email = "Email is required";
    if (!formData.password) tempErrors.password = "Password is required";
    if (!formData.mobile) tempErrors.mobile = "Mobile number is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      alert("Registration successful");
    }
  };

  return (
    <div className="container my-lg-5">
      <h2 className="text-center mb-5">Register</h2>
      <div className="row">
        <div className="col-lg-6 d-flex">
          <img src="https://roommanager.com/wp-content/uploads/2023/04/AdobeStock_356331734-scaled.jpeg" alt="Register" width={600} />
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="bg-light p-5">
            <form onSubmit={handleSubmit}>
              <div className="form-group mb-3">
                <label htmlFor="username" className="form-label">
                  <FaUser className="mr-1 usericon" /> Username
                </label>
                <input
                  type="text"
                  className="form-control p-3"
                  placeholder="Enter username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
                {errors.username && <small className="text-danger">{errors.username}</small>}
              </div>
              <div className="form-group mb-3">
                <label htmlFor="email" className="form-label">
                  <FaEnvelope className="mr-1 usericon" /> Email
                </label>
                <input
                  type="email"
                  className="form-control p-3"
                  placeholder="Enter email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {errors.email && <small className="text-danger">{errors.email}</small>}
              </div>
              <div className="form-group mb-3">
                <label htmlFor="mobile" className="form-label">
                  <FaPhoneAlt className="mr-1 usericon" /> Mobile
                </label>
                <input
                  type="text"
                  className="form-control p-3"
                  placeholder="Enter mobile number"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                />
                {errors.mobile && <small className="text-danger">{errors.mobile}</small>}
              </div>
              <div className="form-group mb-3">
                <label htmlFor="password" className="form-label">
                  <FaLock className="mr-1 usericon" /> Password
                </label>
                <input
                  type="password"
                  className="form-control p-3"
                  placeholder="Enter password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                {errors.password && <small className="text-danger">{errors.password}</small>}
              </div>
              <div className="form-group text-center">
                <button className="px-4 py-1 loginbtn border mt-4 rounded" type="submit">Register</button>
                <p className='pt-3'>Already have an account? <Link className='rbtn' to="/login">Login</Link></p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RegisterComponent;
