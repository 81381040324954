import React from 'react';
import { Link } from 'react-router-dom';
import { FaPhoneAlt, FaEnvelope, FaAngleRight } from 'react-icons/fa';
import { FaInstagram, FaPhoneVolume } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaFacebook } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
const Footer = () => {
  return (
    <>
      <div className='footerbg'>
        <div className="container">
          <div className="row pt-5 pb-0 px-5 px-lg-3">
            <div className="col-lg-3 col-md-12">
            <h5 className="text-uppercase text-light mb-4">About Company</h5>
              <p className='text-light pt-2'>
              Clearmood (Habiteliv), established in 2024, offers high-quality, and customizable water bottles for personal and business use.
                {/* Established in 2024, Clearmood (Habiteliv) is dedicated to providing high-quality, eco-friendly, and customizable water bottles for both personal and business use.
                As premium water bottle dealers. */}
              </p>
            </div>
            <div className="col-lg-3 col-md-12 mb-3 px-lg-5">
              <h5 className="text-uppercase text-light mb-4">Quick Links</h5>
              <div className="d-flex flex-column justify-content-start">
                <Link className="text-light mb-2 text-decoration-none font-weight-bold" to="/product">
                  <FaAngleRight className="text-white mr-lg-2" />Product
                </Link>
              
            
           
                <Link className="text-light mb-2 text-decoration-none font-weight-bold" to="/about">
                  <FaAngleRight className="text-white mr-lg-2" />About Us
                </Link>
                <Link className="text-light mb-2 text-decoration-none font-weight-bold" to="/contact">
                  <FaAngleRight className="text-white mr-lg-2" />Contact Us
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-12 mb-4">
              <h5 className="text-uppercase text-light mb-4">Get In Touch</h5>
              <p className="mb-2 text-light d-flex align-items-center">
                <FaPhoneAlt className="text-white mr-3" />
                <a href="tel:+91-9236336880" className="text-light text-decoration-none font-weight-bold">+91-9236336880</a>
              </p>
              <p className="mb-2 text-light d-flex align-items-center">
                <FaPhoneAlt className="text-white mr-3" />
                <a href="tel:+91-9236322922" className="text-light text-decoration-none font-weight-bold">+91-9236322922</a>
              </p>
              <p className="text-light d-flex align-items-center">
                <FaEnvelope className="text-white mr-3" />
                <a href="mailto:care@clearmood.in" className="text-light text-decoration-none font-weight-bold">care@clearmood.in</a>
              </p>           
            </div>
            <div className="col-lg-3 col-md-12 mb-3">
            <h5 className="text-uppercase text-light mb-4">Address</h5>         
            
            {/* <p className="mb-2  d-flex align-items-center">
               
                <a href="https://maps.google.com/?q=Z34+FLAT+NO.14,+III+rd+FLOOR+MAHENDRA,+Shastri+Nagar,+Ghaziabad,+Ghaziabad-+201002,+Uttar+Pradesh" 
                   target="_blank" 
                   rel="noopener noreferrer" 
                   className="text-light text-decoration-none">
                     <FaLocationDot className="text-white mr-2" />
                    Head Office - Z34 FLAT NO.14, III rd FLOOR MAHENDRA, Shastri Nagar, Ghaziabad, Ghaziabad- 201002, Uttar Pradesh
                </a>
            </p> */}
            
            <p className="mb-2 d-flex align-items-center">
               
                <a href="https://maps.google.com/?q=Z34+FLAT+NO.14,+III+rd+FLOOR+MAHENDRA,+Shastri+Nagar,+Ghaziabad,+Ghaziabad-+201002,+Uttar+Pradesh" 
                   target="_blank" 
                   rel="noopener noreferrer" 
                   className="text-light text-decoration-none">
                     <FaLocationDot className="text-white mr-2" />
                     Head Office - Z34 FLAT NO.14, III rd FLOOR MAHENDRA, Shastri Nagar, Ghaziabad, Ghaziabad- 201002, Uttar Pradesh
                </a>
            </p>
        </div>
          </div>
          <div className="contact-icons-container text-center d-flex justify-content-center pb-2">
            <a href="tel:+91-9236322922" className="contact-icon-wrapper">
                <FaPhoneVolume className="contact-icon" />
            </a>
            <a href="mailto:care@clearmood.in" className="contact-icon-wrapper">
                <FaEnvelope className="contact-icon" />
            </a>
            <a href="https://wa.me/9236322922" className="contact-icon-wrapper" target="_blank" rel="noopener noreferrer">
                <IoLogoWhatsapp className="contact-icon" />
            </a>
            <a href="https://www.facebook.com/asthananews24x7/photos" className="contact-icon-wrapper" target="_blank" rel="noopener noreferrer">
                <FaFacebook className="contact-icon" />
            </a>
            <a href="https://www.instagram.com/habiteliv/" className="contact-icon-wrapper" target="_blank" rel="noopener noreferrer">
                <FaInstagram className="contact-icon" />
            </a>
        </div>
        </div>
      </div>
      <div className=" copyright copyright-bg">
        <p className="py-2 mb-0 text-center text-white">
          &copy; <a href="/" className="text-decoration-none text-white">2024 Clearmood Industries PVT. LTD.</a> All Rights Reserved
        </p>
      </div>
    </>
  );
}

export default Footer;
