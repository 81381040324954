import React, { useEffect, useRef } from 'react';
import { FaTruck, FaHeadset } from 'react-icons/fa';
import { GiWaterDrop, GiHealthNormal } from 'react-icons/gi';
import { FaCircleArrowRight } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import sideline from '../../assests/images/sideline.png'
import 'bootstrap/dist/css/bootstrap.min.css';
const Whychoose = () => {
    const leftColumnRef = useRef(null);
    const rightColumnRef = useRef(null);
    useEffect(() => {
        const handleScroll = () => {
            if (leftColumnRef.current && rightColumnRef.current) {
                const leftElementPosition = leftColumnRef.current.getBoundingClientRect().top;
                const rightElementPosition = rightColumnRef.current.getBoundingClientRect().top;
                const screenPosition = window.innerHeight / 1.3;
                if (leftElementPosition < screenPosition) {
                    leftColumnRef.current.classList.add('visible');
                }
                if (rightElementPosition < screenPosition) {
                    rightColumnRef.current.classList.add('visible');
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className='my-lg-5 pb-5 '>
            <div className="container ">
                <div className="row">
                    <div className="col-lg-6 col-md-12 px-5 m-0 p-0 slide-in-left" ref={leftColumnRef}>
                        <div>
                            <h3 className=" pb-lg-1 sideheadings">Why Choose Us : </h3>
                            <h5>Hydration Redefined with Habit Endless Purity</h5>
                            <img src={sideline} alt='sideline' />
                            <p className="text-justify custome-line-height pt-2 pb-4">
                            Choose Clearmood (Habitliv) for high-quality, customizable water bottles, crafted with eco-friendly materials and state-of-the-art technology. Established in 2024, we prioritize precision, hygiene, and sustainability, ensuring that our products meet your hydration needs with excellence and care. Choose us for exceptional quality, personalized service, and a commitment to your well-being.
                            </p>
                        </div>
                        <Link to='/contact' className="text-center font-weight-bold text-decoration-none servicebtn">
                            Contact Us <FaCircleArrowRight className='ml-2 ' />
                        </Link>
                    </div>
                    <div className="col-lg-6 col-md-12 slide-in-right" ref={rightColumnRef}>
                        <div className='row whyrow'>
                            <div className='col-md-6 mb-3 mb-md-0'>
                                <div className="icon-circle-why d-flex justify-content-center align-items-center mb-2">
                                    <GiWaterDrop className='bestfeicon' />
                                </div>
                                <div className=''>
                                    <h4>Advanced Filtration</h4>
                                    <p>Our bottles use multi-stage filtration to ensure every sip is pure and safe.</p>
                                </div>
                            </div>
                            <div className='col-md-6 mb-3 mb-md-0 '>
                                <div className="icon-circle-why d-flex justify-content-center align-items-center mb-2">
                                    <FaTruck className='bestfeicon' />
                                </div>
                                <div className='whydata '>
                                    <h4>Quick Delivery</h4>
                                    <p>We offer fast and reliable delivery services right to your doorstep.</p>
                                </div>
                            </div>
                        </div>
                        <div className='row whyrow'>
                            <div className='col-md-6 mb-3 mb-md-0 '>
                                <div className="icon-circle-why d-flex justify-content-center align-items-center mb-2">
                                    <GiHealthNormal className='bestfeicon' />
                                </div>
                                <div className='whydata'>
                                    <h4>Health Benefits</h4>
                                    <p>Drink water that's not just clean, but also beneficial for your health.</p>
                                </div>
                            </div>
                            <div className='col-md-6 '>
                                <div className="icon-circle-why d-flex justify-content-center align-items-center mb-2">
                                    <FaHeadset className='bestfeicon' />
                                </div>
                                <div className='whydata '>
                                    <h4>24/7 Support</h4>
                                    <p>Our customer support is available around the clock to assist you.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Whychoose;
