import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import {FaSearch  } from 'react-icons/fa';
import linenew from '../../assests/images/linenew.png'
import axios from 'axios';

const Allproducts = () => {

    const [products, setProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const getProduct = () =>{
        axios.get(process.env.REACT_APP_BACKEND_URL + '/product').then((data)=>{
            if(data){
                setProducts(data.data)
            }
        })
    }

    useEffect(() => {
        if(searchTerm.length){
            const filtered = products.filter(item =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase())
              );
              setProducts(filtered);
        }else{
            getProduct()
        }
      }, [searchTerm]);


    return (
        <div className='container my-5 '>
            <div className='text-center pb-5'>
             <h3 className="whyh1 pb-1">All Products</h3>
             <h5>Discover our extensive range of products.</h5>
                <img src={linenew} alt="" className='text-center' width={250} />
            </div>
            <div className="row text-center pb-5 px-4"> 
            <div className='col-3'></div>                  
                <div className='col-md-6 d-flex justify-content-center align-items-center sinput rounded'>
                    <input type="text" className="w-100" placeholder="Search products..."  onChange={(e) => setSearchTerm(e.target.value)}/>
                    <button type="button" className="btn">
                        <FaSearch className='searchicon' />
                    </button>
                </div>
                <div className='col-3'></div>
            </div>
            <div className="row">
                {products.map(product => (
                    <div key={product.id} className="col-md-4 mb-5">
                        <div className="product text-center">
                            <div className="position-relative">
                                <img className="product-image w-100 productcardbg productcard p-4" src={`${process.env.REACT_APP_BACKEND}uploads/${product.image}`} alt={product.name} />
                                {/* <FaPlus  className='filtericon p-2' size={40} style={{ position: 'absolute', top: '30px', right: '30px', zIndex: '20', background:'#fff', borderRadius:"50%" }} />  */}
                            </div>
                            <div className="card-body pcardbody ">
                                <h4 className="fs-5 ">{product.name}</h4>
                                <p className='text-success font-weight-bold fs-5'>{product.offer}</p>
                                <p className=''>{product.discription}</p>
                                <h6 className="fw-bolder fs-4">Rs {product.sprice}</h6>
                                <div className="mt-3">
                                    <Link to={`/productdetails/${product.id}`}>
                                    <button className="btn text-white px-5 font-weight-bold">More Info</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Allproducts;
