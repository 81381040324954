import React from 'react'
import Contactus from '../../components/contactus/Contactus'
import Contacthero from '../../components/contactus/Contacthero'
import Bestfe1 from '../../components/bestfe/Bestfe1'
import Gmap from '../../components/contactus/Gmap'
const Contact = () => {
  return (
    <div>     
      <Contacthero /> 
      <Contactus />
      <Gmap />
    </div>
  )
}
export default Contact
