import React, { useEffect, useRef, useState } from 'react';
import vission from '../../assests/images/vissionew.png';
import value from '../../assests/images/valuenew.png';
import mission from '../../assests/images/missionnew1.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import sideline from '../../assests/images/sidelinem.png'
const Mission = () => {
    const [isVisible, setIsVisible] = useState(false);
    const visionRef = useRef(null);
    const missionRef = useRef(null);
    const valuesRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (visionRef.current && missionRef.current && valuesRef.current) {
                const visionPosition = visionRef.current.getBoundingClientRect().top;
                const missionPosition = missionRef.current.getBoundingClientRect().top;
                const valuesPosition = valuesRef.current.getBoundingClientRect().top;
                const screenPosition = window.innerHeight / 1.3; // Adjust as needed

                setIsVisible({
                    vision: visionPosition < screenPosition,
                    mission: missionPosition < screenPosition,
                    values: valuesPosition < screenPosition,
                });
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll(); 

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className='bestfe-banner mb-lg-0 mb-5 py-5'>
            <div className='container my-lg-5'>
                <div className="row px-lg-0 px-3">
                    <div className={`col-lg-7 order-2 order-md-1 ${isVisible.vision ? 'visible' : 'hidden'} slide-in-left`} ref={visionRef}>
                        <div className="text-justify">
                            <h3 className="sideheadings pb-1">Our Vision : </h3>
                            <h5>At Clearmood (Habitliv), our vision is to</h5>
                            <img src={sideline} alt='sideline' />
                            <ul className="px-4 custome-line-height py-lg-2">
                                <li>Lead globally in providing clean, safe drinking water.</li>
                                <li>Ensure access to water free from harmful contaminants.</li>
                                <li>Transform communities with advanced purification technology.</li>
                                <li>Promote a healthier, sustainable future with accessible pure water.</li>
                            </ul>
                        </div>
                    </div>
                    <div className={`col-lg-5 order-1 order-md-2 ${isVisible.vision ? 'visible' : 'hidden'} slide-in-right`} ref={visionRef}>
                        <img src={vission} alt='vission' className='missionimg' />
                    </div>
                </div>
            </div>
            <div className='pt-5'>
                <div className='container mt-lg-5'>
                    <div className='row px-lg-0 px-3'>
                        <div className={`col-lg-5 ${isVisible.mission ? 'visible' : 'hidden'} slide-in-left`} ref={missionRef}>
                            <img src={mission} alt='Mission' className='mission_img' />
                        </div>
                        <div className={`col-lg-7 ${isVisible.mission ? 'visible' : 'hidden'} slide-in-right`} ref={missionRef}>
                            <div className='text-justify'>
                                <h3 className="sideheadings pb-1">Our Mission : </h3>
                                <h5>At Clearmood (Habitliv), our mission is to</h5>
                                <img src={sideline} alt='sideline' />
                                <ul className='px-4 custome-line-height py-lg-2'>
                                    <li>Provide clean and safe drinking water to all.</li>
                                    <li>Eliminate harmful contaminants in water.</li>
                                    <li>Implement innovative purification solutions worldwide.</li>
                                    <li>Support a sustainable future with pure water for everyone.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container mt-lg-5'>
                    <div className="row pt-5 px-lg-0 px-3">
                        <div className={`col-lg-7 order-2 order-md-1 ${isVisible.values ? 'visible' : 'hidden'} slide-in-left`} ref={valuesRef}>
                            <div className="text-justify">
                                <h3 className="sideheadings pb-1">Our Values : </h3>
                                <h5>At Clearmood (Habitliv), our values are to</h5>
                                <img src={sideline} alt='sideline' />
                                <ul className="px-4 custome-line-height py-lg-2">
                                    <li>Commit to quality and purity in water.</li>
                                    <li>Ensure sustainability in our practices.</li>
                                    <li>Innovate for better water solutions.</li>
                                    <li>Serve communities with integrity and care.</li>
                                </ul>
                            </div>
                        </div>
                        <div className={`col-lg-5 order-1 order-md-2 ${isVisible.values ? 'visible' : 'hidden'} slide-in-right`} ref={valuesRef}>
                            <img src={value} alt='Values' className='missionimg' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Mission;
