import React, { useState } from 'react';
import { FaMapMarkerAlt, FaEnvelopeOpen, FaPhoneAlt } from 'react-icons/fa';
import sideline from '../../assests/images/sideline.png';
const Contactus = () => {
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        subject: '',
        message: '',
    });
    const [errors, setErrors] = useState({});
    const validate = () => {
        const errors = {};
        if (!formData.name) {
            errors.name = "Name is required";
        }
        const phoneRegex = /^[0-9]{10}$/;
        console.log(parseInt(formData.mobile))
        if (!phoneRegex.test(parseInt(formData.mobile))) {
            errors.mobile = "Phone number must be 10 digits";
        }
        if (!formData.subject) {
            errors.subject = "Subject is required";
        }
        if (!formData.message) {
            errors.message = "Message is required";
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {         
            console.log('Form data:', formData);
            const rawResponse = await fetch('http://localhost:8000/api/v1/contactus/', {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
              });
            alert('Form submitted successfully!');
            setFormData({
                name: '',
                mobile: '',
                subject: '',
                message: '',
            });
        }
    };
    return (
        <div className="container my-5">           
                <div className="pt-lg-5 row">
            <div className="col-lg-5 col-md-12">
                    <div className="d-flex flex-column justify-content-center px-3 py-5 contactinfo" >
                        <div className="d-flex my-3">
                            <FaMapMarkerAlt className="fa-2x contacticon flex-shrink-0 mr-3" />
                            <div>
                                <h5 className="text-white">Address</h5>
                                <p><a className="text-white text-decoration-none" href="https://maps.google.com/?q=Z34+FLAT+NO.14,+III+rd+FLOOR+MAHENDRA,+Shastri+Nagar,+Ghaziabad,+Ghaziabad-+201002,+Uttar+Pradesh" target="_blank" rel="noopener noreferrer">Head Office - Z34 FLAT NO.14, III rd FLOOR MAHENDRA, Shastri Nagar, Ghaziabad, Ghaziabad- 201002, Uttar Pradesh</a></p>
                                <p><a className="text-white text-decoration-none" href="https://maps.google.com/?q=Maharajpur,+Mantri+Nagar,+Badi+Mai+Campus+Colony,+Near+Bachpan+Play+School,+Jabalpur,+India,+Madhya+Pradesh" target="_blank" rel="noopener noreferrer">Branch Office - Maharajpur, Mantri Nagar, Badi Mai Campus Colony, Near Bachpan Play School, Jabalpur, India, 482001</a></p>
                            </div>
                        </div>
                        <div className="d-flex my-3">
                            <FaEnvelopeOpen className="fa-2x contacticon flex-shrink-0 mr-3" />
                            <div>
                                <h5 className="text-white">Email Address</h5>
                                <p><a href="mailto:care@clearmood.in" className="text-light text-decoration-none">care@clearmood.in</a></p>
                                {/* <p><a href="mailto:hr@clearmood.in" className="text-light text-decoration-none">hr@clearmood.in</a></p>         */}
                            </div>
                        </div>
                        <div className="d-flex my-3">
                            <FaPhoneAlt className="fa-2x contacticon flex-shrink-0 mr-3" />
                            <div>
                                <h5 className="text-white">Contact Number</h5>
                                <p className="m-0"><a href="tel:+91-9236336880" className="text-light text-decoration-none">+91-9236336880</a></p>
                                <p className="m-0"><a href="tel:+91-9236322922" className="text-light text-decoration-none">+91-9236322922</a></p>
                                {/* <p className="m-0"><a href="tel:+91-8318994342" className="text-light text-decoration-none">+91-8318994342</a></p> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 col-md-12 mt-lg-0 mt-5 px-lg-0 px-3 ">
                <h3 className="pb-lg-1 pl-lg-4 sideheadings">Contact Us</h3>
                        <h5 className='pl-lg-4'>Get in Touch With Us</h5>
                        <img src={sideline} alt="sideline" className="img-fluid pl-lg-4" />
                    <h6 className='pl-lg-4 custome-line-height py-lg-4'>Got a question or need assistance? Fill out the form below and we’ll get back to you shortly! We're here to help and look forward to hearing from you.</h6>
                    <div className="contact-form bg-light mb-lg-5 p-4">
                        <form>
                            <div className="row">
                                <div className="col-12 col-md-6 form-group mb-3">
                                    <input
                                        type="text"
                                        className="form-control p-4"
                                        placeholder="Your Name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                    {errors.name && <small className="text-danger">{errors.name}</small>}
                                </div>
                                <div className="col-12 col-md-6 form-group mb-3">
                                    <input
                                        type="tel"
                                        className="form-control p-4"
                                        placeholder="Your Phone"
                                        name="mobile"
                                        value={formData.mobile}
                                        onChange={handleChange}
                                        required
                                    />
                                    {errors.mobile && <small className="text-danger">{errors.mobile}</small>}
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <input
                                    type="text"
                                    className="form-control p-4"
                                    placeholder="Subject"
                                    name="subject"
                                    value={formData.subject}
                                    onChange={handleChange}
                                    required
                                />
                                {errors.subject && <small className="text-danger">{errors.subject}</small>}
                            </div>
                            <div className="form-group mb-3">
                                <textarea
                                    className="form-control py-3 px-4"
                                    rows="5"
                                    placeholder="Message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                ></textarea>
                                {errors.message && <small className="text-danger">{errors.message}</small>}
                            </div>
                            <div className='py-2'>
                                <button className="btn submitbtn" onClick={handleSubmit}>Send Message</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contactus;
