import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Home from './pages/home/Home';
import About from './pages/about/About';
import Product from './pages/product/Product';
import Career from './pages/career/Career';
import Gallery from './pages/gallery/Gallery';
import Contact from './pages/contact/Contact';
import Footer from './components/footer/Footer';
import Login from './pages/user/Login';
import Register from './pages/user/Register';
import Cart from './pages/cart/Cart';
import Checkout from './pages/checkout/Checkout';
import Productdetails from './components/products/Productdetails';
import Enquiryform from './components/products/Enquiryform';
import Notfound from './pages/notfound/Notfound'; 
import Faq from './components/aboutcompany/Faq';
import Stickyicon from './components/bestfe/Stickyicon'
import {Ourcoreteam} from './pages/team/Ourcoreteam'
function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product" element={<Product />} />
        <Route path="/about" element={<About />} />
        <Route path="/career" element={<Career />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/productdetails/:id" element={<Productdetails />} />
        <Route path="/enquiryform" element={<Enquiryform />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/ourcoreteam" element={<Ourcoreteam />} />
        <Route path="*" element={<Notfound />} /> 
      </Routes>
      <Footer />
      <Stickyicon/>
    </>
  );
}

export default App;
