import React from 'react';
import men from '../../assests/images/boy.png';
import { FaTruck, FaRegCalendarAlt, FaPhoneAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { FaCircleArrowRight } from "react-icons/fa6";
import VideoThumbnail from '../../assests/images/bannerpurity.png';
import Counter from './Counter';
const Bestfe3 = () => {
    return (
        <div>
            {/* <div className='mt-5'>
                <div className='besfrerow'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-5 col-md-12 d-flex justify-content-center'>
                                <img src={men} alt='men' className='imgdelvery img-fluid ' />
                            </div>
                            <div className='col-lg-7 col-md-12 '>
                                <p className='delevery' >Delivery <span className='servicetxt'>Service</span></p>
                                <p className='py-3 deleveryp  pr-lg-3'>Our delivery service employs more than 100 professional couriers. We guarantee prompt delivery of water to your home, ensuring quick service across the entire city.</p>
                                <div className="delivery-icons">
                                    <h6 className='featur'><FaTruck className='pr-2 develveryicon' />Free Delivery</h6>
                                    <h6 className='featur'><FaRegCalendarAlt className='pr-2 develveryicon' />7 days a week</h6>
                                    <h6 className='featur'><FaPhoneAlt className='pr-2 develveryicon' />24/7 Support</h6>
                                </div>
                                <div className="py-lg-5 bestfecontactbtn">
                                    <Link to='/about' className="text-center font-weight-bold text-decoration-none dbtn mb-3  mr-3">
                                        Read More <FaCircleArrowRight className='ml-2 ' />
                                    </Link>
                                    <Link to='/contact' className="text-center font-weight-bold text-decoration-none dbtn">
                                        Contact Us <FaCircleArrowRight className='ml-2 ' />
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div> */}
            <div className=''>
                <img  src={VideoThumbnail} className='w-100 img-fluid
                ' height={500}/>
            </div>
              <div className='besfrerow'>
      <div className='container '>
        <div className='row py-5 mb-5'>
                        {/* <div className='row dbanner py-3'> */}
                            <div className='col-lg-12 col-md-6'>
                                <div className="row py-lg-5">
                                    <div className="col-lg-3 col-md-12  counts">
                                        <Counter icon="code" target={500} duration={1500} text="Sustainable Partnerships" />
                                    </div>
                                    <div className="col-lg-3 col-md-12  counts">
                                        <Counter icon="coffee" target={500} duration={1500} text="Happy Customers" />
                                    </div>
                                    <div className="col-lg-3 col-md-12 counts">
                                        <Counter icon="lightbulb" target={1000} duration={1500} text="Customer Engagement" />
                                    </div>
                                    <div className="col-lg-3 col-md-12 counts">
                                        <Counter icon="employees" target={200} duration={1500} text="Employees" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
        </div>
        </div>
        
    );
};

export default Bestfe3;
