import React, { useEffect, useRef } from 'react';
import { FaFilter, FaBolt, FaLaptop, FaCompress, FaTools, FaTint } from 'react-icons/fa';
import linenew from '../../assests/images/linenew.png'
const Features = () => {
    const featureRefs = useRef([]);
    useEffect(() => {
        const handleScroll = () => {
            featureRefs.current.forEach(ref => {
                if (ref) {
                    const elementPosition = ref.getBoundingClientRect().top;
                    const screenPosition = window.innerHeight / 1.3;

                    if (elementPosition < screenPosition) {
                        ref.classList.add('visible');
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll(); 

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className="container my-lg-5">
             <div className='text-center pb-lg-5'>
             <h3 className="pb-1">Our Features </h3>
             <h5>Cutting-Edge Filtration, Eco-Friendly Efficiency, Intelligent Design</h5>
                <img src={linenew} alt="" className='text-center' width={250} />
            </div>
            <div className="row text-justify">
                <div className="col-md-12 text-center">
                    <div className='row text-center pt-5'>
                        <div className='col-lg-4 mb-4 col-md-6 slide-in-left featurescol' ref={el => featureRefs.current[0] = el}>
                            <div className='servicecarbg p-4'>
                                <div className='text-center'>
                                    <FaFilter className='vicons' />
                                </div>
                                <div className='pt-3'>
                                    <h5>Cutting-Edge Filtration</h5>
                                    <p>Our bottles use multi-stage filtration to ensure every drop is pure and safe.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 mb-4 col-md-6 slide-in-top featurescol' ref={el => featureRefs.current[1] = el}>
                            <div className='servicecarbg p-4'>
                                <div className='text-center'>
                                    <FaBolt className='vicons' />
                                </div>
                                <div className='pt-3'>
                                    <h5>Eco-Friendly Efficiency</h5>
                                    <p>Designed with sustainability in mind, using eco-friendly materials and processes.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 mb-4 col-md-6 slide-in-right featurescol' ref={el => featureRefs.current[2] = el}>
                            <div className='servicecarbg p-4'>
                                <div className='text-center'>
                                    <FaLaptop className='vicons' />
                                </div>
                                <div className='pt-3'>
                                    <h5>Intelligent Design</h5>
                                    <p>Smart and user-friendly designs for convenience and ease of use.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4 mb-4 col-md-6 slide-in-left featurescol' ref={el => featureRefs.current[3] = el}>
                            <div className='servicecarbg p-4'>
                                <div className='text-center'>
                                    <FaTools className='vicons' />
                                </div>
                                <div className='pt-3'>
                                    <h5>Easy Maintenance</h5>
                                    <p>Simple and hassle-free maintenance to keep your bottle in top condition.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 mb-4 col-md-6 slide-in-bottom featurescol' ref={el => featureRefs.current[4] = el}>
                            <div className='servicecarbg p-4'>
                                <div className='text-center'>
                                    <FaTint className='vicons' />
                                </div>
                                <div className='pt-3'>
                                    <h5>Superior Taste</h5>
                                    <p>Enjoy the crisp and refreshing taste of purified water in every sip.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 mb-4 col-md-6 slide-in-right featurescol' ref={el => featureRefs.current[5] = el}>
                            <div className='servicecarbg p-4'>
                                <div className='text-center'>
                                    <FaCompress className='vicons' />
                                </div>
                                <div className='pt-3'>
                                    <h5>Portable Design</h5>
                                    <p>Sleek, portable design suitable for any lifestyle, whether at home, work.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Features;
