import React, { useEffect, useRef } from 'react';
import linenew from '../../assests/images/linenew.png';

const Careerinfo = () => {
    const careerRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (careerRef.current) {
                const elementPosition = careerRef.current.getBoundingClientRect().top;
                const screenPosition = window.innerHeight / 1.3;
                if (elementPosition < screenPosition) {
                    careerRef.current.classList.add('visible');
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="container my-5">
            <div className="text-center pb-5">
                <h3 className="whyh1 pb-1">Career</h3>
                <h5>Exciting Career Opportunities at Habitliv</h5>
                <img src={linenew} alt="Line Image" className='text-center' width={250} />
            </div>
            <div ref={careerRef} className="slide-in-bottom">
                <div className="col-md-12">
                    <div className="careerinfo">
                        <p className='text-justify  abut-detail px-lg-0 px-3'>Exciting career opportunities await individuals passionate about making a difference in the world of hydration with Habitliv. Whether you're a recent graduate or an experienced professional, we offer rewarding career paths in various roles within our company.</p>
                        <p className='text-justify  abut-detail px-lg-0 px-3'>At Habitliv, we're committed to excellence and innovation in every aspect of our business. Join our team and be part of a company that strives to provide the highest quality water solutions while promoting sustainability and environmental responsibility.</p>
                        <p className='text-justify  abut-detail px-lg-0 px-3'>We offer competitive compensation packages, ongoing training, and opportunities for career advancement. If you're ready to embark on a fulfilling career journey with Habitliv, we invite you to explore our current job openings and apply today!</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Careerinfo;
