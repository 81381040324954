import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaCircleArrowRight } from "react-icons/fa6";
import b3 from '../../assests/images/best1.png'
import sideline from '../../assests/images/sideline.png'
import 'bootstrap/dist/css/bootstrap.min.css';
const AboutCompany = () => {
  const b2Ref = useRef(null);
  const b4Ref = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      if (b2Ref.current && b4Ref.current) {
        const b2Position = b2Ref.current.getBoundingClientRect().top;
        const b4Position = b4Ref.current.getBoundingClientRect().top;
        const screenPosition = window.innerHeight / 1.3;

        if (b2Position < screenPosition) {
          b2Ref.current.classList.add('visible');
        }
        if (b4Position < screenPosition) {
          b4Ref.current.classList.add('visible');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="about-company">
      <div className="container my-5">
        <div className="row py-lg-5">
          <div className="col-lg-5 col-sm-12 ">
            <img
              src={b3}
              alt="b3"
              width={450}
              height={450}
              className="slide-in-left img-fluid shadow aboutimg"
              ref={b2Ref}
            />
          </div>
          <div className="col-lg-7 col-12 px-5  slide-in-right" ref={b4Ref}>
            <div>
              <h3 className="pb-lg-1 sideheadings">About - Habit Endless Purity :</h3>
              <h5>Redefining Hydration with Quality and Purity</h5>
              <img src={sideline} alt="sideline" className="img-fluid" />
              <p className="text-justify custom-line-height py-3">
                Established in 2024, Clearmood (Habiteliv Endless Purity) is dedicated to providing high-quality, eco-friendly, and customizable water bottles for both personal and business use.
                As premium water bottle dealers, our commitment to sustainability and excellence drives us to continually improve our hydration solutions to meet the diverse needs of our clients. We use state-of-the-art, highly sanitary machinery to ensure precision and hygiene in every bottle we produce.
              </p>
            </div>
            <div className="mt-lg-4 ">
              <Link to="/contact" className="font-weight-bold text-decoration-none servicebtn">
                Contact Us <FaCircleArrowRight className="ml-2 " />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCompany;
