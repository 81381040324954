import React from 'react'
import Checkoutcompoent from '../../components/cart/Checkoutcompoent'
const Checkout = () => {
  return (
    <div>
      <Checkoutcompoent />
    </div>
  )
}

export default Checkout
