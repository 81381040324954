import React from 'react';
import Head from '../../components/aboutcompany/Head';
import Aboutcompany from '../../components/aboutcompany/Aboutcompany';
import Whychoose from '../../components/aboutcompany/Whychoose';
import Feedback from '../../components/feedback/Feedback';
import Mission from '../../components/aboutcompany/Mission';
import Features from '../../components/aboutcompany/Features';
import Bestfe2 from '../../components/bestfe/Bestfe2';
const About = () => {
  return (
    <div >
      <Head />
      <Aboutcompany />
      <Whychoose /> 
      <Mission />  
      {/* <Features />
      <Bestfe2 />   
      <Feedback /> */}
    </div>
  );
};

export default About;
