import React from 'react'
import { Link } from 'react-router-dom'
const Producthere = () => {
  return (
    <div>
       <div className="product-head">
                <div className="py-5 text-center">
                    <div className="py-5">
                        <h3 className='mb-2 text-dark'>Product</h3>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center bg-transparent">
                                <li className="breadcrumb-item">
                                    <Link to="/about" className='text-dark text-decoration-none'>About</Link>
                                </li>
                                <li className="breadcrumb-item active activepage" aria-current="page">Product</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default Producthere
