import React from 'react';
const Qualificationneed = () => {
    const careerDetails = [
        { key: 'Qualifications', value: 'Degree or Diploma in any relevant field from a recognized institution' },
        { key: 'Age Requirement', value: 'Maximum age of 25 years as of the application deadline' },
        { key: 'Selection Process', value: 'Through comprehensive interviews conducted by Habit Endless Purity.' },
        { key: 'Orientation Training', value: '10-day induction program at the Habit Endless Purity Training Institute' },
        { key: 'Internship', value: 'Six-month internship program providing hands-on experience in the field' },
        { key: 'Vacancies & Posting', value: 'multiple positions available with postings near candidates\' hometowns' },
        { key: 'Stipend', value: 'Rs. 10,000 per month during the internship period' },
        { key: 'Absorption in Company', value: 'Successful interns will be absorbed into full-time positions' },
        { key: 'Salary upon Absorption', value: 'Starting salary of Rs. 15,000 per month' },
        { key: 'Application Submission', value: 'Applications should be sent to Habit Endless Purity' },
        { key: 'Email for Applications', value: 'Send applications to hr@clearmood.in' },
        { key: 'Contact Number', value: 'Contact Number: +91-9236322922' },
    ];
    return (
        <div>
            <div className='container qualificationdetail'>
                <h6 className='text-justify coreerinfo2' >
                    Aspiring candidates will be appointed as Executives nationwide, often in their hometowns. Below are the specifications and selection process details followed by Habit Endless Purity.
                </h6>     
                <table className="table table-bordered my-5 ">
                    <thead className="careerhead">
                        <tr className='text-white careerhead'>
                            <th scope="col">S.No</th>
                            <th scope="col">Specification</th>
                            <th scope="col">Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {careerDetails.map((detail, index) => (
                            <tr key={index}>
                                <th scope="row" className='text-center'>{index + 1}</th>
                                <td>{detail.key}</td>
                                <td>{detail.value}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
export default Qualificationneed;
