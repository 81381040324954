import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FaMinus, FaPlus, FaStar, FaFileDownload, FaShoppingBasket, FaShareAlt, FaStarHalfAlt } from 'react-icons/fa';
import Similarproduct from './Similarproduct';
import { FaWpforms } from 'react-icons/fa6';
import {useParams} from 'react-router-dom';
import axios from 'axios';
const ProductDetails = () => {
  const [quantity, setQuantity] = useState(1);
  const [product, setProducts] = useState({});
  const { id } = useParams(); 

  const getProduct = () =>{
    axios.get(process.env.REACT_APP_BACKEND_URL + 'product/one/' + id ).then((data)=>{
        if(data){
            setProducts(data.data)
        }
    })
}

useEffect(()=>{
    getProduct();
}, [id])

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  const handleIncrease = () => {
    setQuantity(quantity + 1);
  };
  return (
    <>
      <div className='container'>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb bg-white">
            <li className="breadcrumb-item">
              <Link to="/" className='text-dark text-decoration-none'>Home</Link>
            </li>
            <li className="breadcrumb-item active activepage" aria-current="page">Productdetails</li>
          </ol> 
        </nav>
      </div>
      <section className="py-lg-5 my-5">
        <div className="container">
          <div className="row">
            <aside className="col-lg-6">
              <div className="border rounded-4 d-flex justify-content-center">
                <a
                  data-fslightbox="mygalley"
                  className="rounded-4"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-type="image"
                  href={`${process.env.REACT_APP_BACKEND}uploads/${product.image}`}
                >
                  <img
                    className="d-block w-100 rounded-4 p-4 mb-2"
                    src={`${process.env.REACT_APP_BACKEND}uploads/${product.image}`}
                    alt="Product"
                    style={{ cursor: 'zoom-in' }}
                  />
                </a>
              </div>
            </aside>
            <main className="col-lg-6">
              <div className="px-3">
                <h2 className="title text-dark prdocutdetialshead">
                  {product.name}  
                  <span className='shareicon'>
                    <FaShareAlt className='mt-n5 share' />
                  </span>          
                </h2>            
                <div className="mb-2">
                  <span className="h5">Rs    {product.sprice}  .00</span>
                  <span className="text-dark">/- per bottle</span>
                </div>
                <p className='custome-line-height text-success font-weight-bold fs-5'>
                {product.offer}  
                </p>
                <p className='custome-line-height'>
                {product.long_description}  
                </p>
                <div className="row py-2">
                  <dt className="col-3">Type:</dt>
                  <dd className="col-9">Insulated</dd>
                  <dt className="col-3">Material</dt>
                  <dd className="col-9">Stainless Steel, BPA-free Plastic</dd>
                  <dt className="col-3">Brand</dt>
                  <dd className="col-9">Habit Endless Purity</dd>
                </div>
                <hr className='pdetailshr' />
                <div className="row mb-3">
                  <div className="col-md-4 col-4 py-2">
                    <label className="">Size:{product.unit} ml  </label>
                  </div>
                </div>   
                <hr className='pdetailshr mb-5' />        
                <Link to="/enquiryform" className="p-2 rounded mr-3 addtocart text-decoration-none">
                  <FaWpforms /> Enquiry Now
                </Link> 
                <a href={`${process.env.REACT_APP_BACKEND}uploads/${product.download_link}`} className="downloadpdf p-2 rounded text-decoration-none" download="myFile.pdf">
                  <FaFileDownload /> Download PDF
                </a>        
              </div>
            </main>
          </div>
        </div>
      </section>
      <Similarproduct />
    </>
  );
};

export default ProductDetails;
