import React, { useState } from 'react';
import email from '../../assests/images/email.png'
import whatsapp from '../../assests/images/whatsapp.png'
import insta from '../../assests/images/insta.png'
import call from '../../assests/images/call.png'
import facbook from '../../assests/images/facebook.png'
import logo from '../../assests/images/mylogo.png'
import { Link, useLocation } from 'react-router-dom';
import { IoClose, IoMenu } from 'react-icons/io5';
import 'bootstrap/dist/css/bootstrap.min.css';


const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const currentLocation = useLocation();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleMenuItemClick = () => {
        setIsOpen(false);
    };

    return (
        <div className="container mb-5">
            <nav className="navbar sidebar navbar-expand-lg fixed-top navbar-scroll">
                <div className="navbar-content d-flex align-items-center justify-content-center">
                    <img src={logo} alt="logo" className="logo" loading="lazy" />
                    <div className="head-icons text-center d-flex justify-content-center pl-lg-5 ml-lg-5">
                        <a href="tel:+91-9236322922" className="head-icon-wrapper">
                        <img src={call} alt='email' className="head-icon" width={25} />
                        </a>
                        <a href="mailto:care@clearmood.in" className="head-icon-wrapper">
                            <img src={email} alt='email' className="head-icon" width={40} />
                        </a>
                        <a href="https://wa.me/9236322922" className="head-icon-wrapper" target="_blank" rel="noopener noreferrer">
                            <img src={whatsapp} alt='email' className="head-icon" width={24} />
                        </a>
                        <a href="https://www.facebook.com/asthananews24x7/photos" className="head-icon-wrapper" target="_blank" rel="noopener noreferrer">
                        <img src={facbook} alt='email' className="head-icon ml-2" width={20} />
                        </a>
                        <a href="https://www.instagram.com/habiteliv/" className="head-icon-wrapper" target="_blank" rel="noopener noreferrer">
                            <img src={insta} alt='email' className="head-icon ml-1" width={29} />
                        </a>
                    </div>
                    <button className="navbar-toggler" type="button" onClick={toggleMenu} aria-expanded={isOpen} aria-label="Toggle navigation">
                        {isOpen ? <IoClose size={24} /> : <IoMenu size={24} />}
                    </button>
                </div>

                <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`}>
                    <div className='pl-lg-5 ml-lg-5 w-100'>
                        <ul className="navbar-nav ml-auto pl-lg-5 ml-lg-5">
                            <li className="nav-item mymenu pl-lg-5 ml-lg-5">
                                <Link to="/" className={`nav-link ${currentLocation.pathname === '/' ? 'active' : ''}`} onClick={handleMenuItemClick}>Home</Link>
                            </li>
                            <li className="nav-item mymenu">
                                <Link to="/about" className={`nav-link ${currentLocation.pathname === '/about' ? 'active' : ''}`} onClick={handleMenuItemClick}>About</Link>
                            </li>
                            <li className="nav-item mymenu">
                                <Link to="/ourcoreteam" className={`nav-link ${currentLocation.pathname === '/ourcoreteam' ? 'active' : ''}`} onClick={handleMenuItemClick}>Our Core Team</Link>
                            </li>
                            <li className="nav-item mymenu">
                                <Link to="/product" className={`nav-link ${currentLocation.pathname === '/product' ? 'active' : ''}`} onClick={handleMenuItemClick}>Product</Link>
                            </li>
                            <li className="nav-item mymenu">
                                <Link to="/gallery" className={`nav-link ${currentLocation.pathname === '/gallery' ? 'active' : ''}`} onClick={handleMenuItemClick}>Gallery</Link>
                            </li>
                            <li className="nav-item mymenu">
                                <Link to="/career" className={`nav-link ${currentLocation.pathname === '/career' ? 'active' : ''}`} onClick={handleMenuItemClick}>Career</Link>
                            </li>
                            <li className="nav-item mymenu">
                                <Link to="/contact" className={`nav-link ${currentLocation.pathname === '/contact' ? 'active' : ''}`} onClick={handleMenuItemClick}>Contact</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Header;
