import React from 'react'
import {Link} from 'react-router-dom'
import Logincomponent from '../../components/usercomponent/Logincomponent'
const Login = () => {
  return (
    <div>
      <nav aria-label="breadcrumb  ">
        <ol className="breadcrumb bg-white">
          <li className="breadcrumb-item"><Link to="/home" className='text-dark text-decoration-none'>Home</Link></li>
          <li className="breadcrumb-item active activepage" aria-current="page">Login</li>
        </ol>
      </nav>
      <Logincomponent />
    </div>
  )
}

export default Login
