import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom'
import { FaUser, FaLock } from 'react-icons/fa';
const Logincomponent = () => {
  const [formData, setFormData] = useState({ username: '', password: '' });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const validate = () => {
    let tempErrors = {};
    if (!formData.username) tempErrors.username = "Username is required";
    if (!formData.password) tempErrors.password = "Password is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      alert("Login successful");
    }
  };

  return (
    <div className="container my-lg-5">
       <h2 className="text-center mb-5">Login</h2>
      <div className="row">
        <div className="col-lg-6 d-flex align-items-center">
          <img src="https://legal.yourexpert.co.uk/wp-content/uploads/2021/06/AdobeStock_356331838-copy.png" alt="Login" className="img-fluid" />
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="bg-light p-5">
            <form onSubmit={handleSubmit}>
              <div className="form-group mb-3">
                <label htmlFor="username" className="form-label"><FaUser className="mr-1 usericon"/> Username</label>
                <input
                  type="text"
                  className="form-control p-3"
                  placeholder="Enter username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
                {errors.username && <small className="text-danger">{errors.username}</small>}
              </div>
              <div className="form-group mb-3">
                <label htmlFor="password" className="form-label"><FaLock className="mr-1 usericon"/> Password</label>
                <input
                  type="password"
                  className="form-control p-3"
                  placeholder="Enter password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                {errors.password && <small className="text-danger">{errors.password}</small>}
              </div>
              <div className="form-group text-center">
                <button className="px-4  py-1 loginbtn border mt-4 rounded " type="submit">Login</button>
                <p className='pt-3'>Dont' have an Account?<Link className='rbtn' to="/register"> Register </Link> </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logincomponent;
