import axios from 'axios';
import React, { useState } from 'react';
const Careerform = () => {
    const [formData, setFormData] = useState({
        jobrole: '',
        pdf: null,
        name: '',
        email: '',
        mobile: '',
        subject: '',
        message: '',
    });
    const [errors, setErrors] = useState({});
    const validate = () => {
        const errors = {};
        if (!formData.jobrole) {
            errors.jobrole = "Job role is required";
        }
        if (!formData.pdf) {
            errors.pdf = "Resume is required";
        }
        if (!formData.name) {
            errors.name = "Name is required";
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            errors.email = "Valid email is required";
        }
        const phoneRegex = /^[0-9]{10}$/;
        if (!phoneRegex.test(formData.mobile)) {
            errors.mobile = "Phone number must be 10 digits";
        }
        if (!formData.subject) {
            errors.subject = "Subject is required";
        }
        if (!formData.message) {
            errors.message = "Message is required";
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleFileChange = (e) => {
        setFormData({ ...formData, pdf: e.target.files[0] });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            console.log('Form data:', formData);

            const formDataToSend = new FormData();
            for (const key in formData) {
                formDataToSend.append(key, formData[key]);
            }
            axios.post(process.env.REACT_APP_BACKEND_URL + '/career', formDataToSend).then((data)=>{
                if(data.data.length){
                    alert('Send')
                }
            }).catch((e)=>{
                console.log(e)
            })

            alert('Form submitted successfully!');
            setFormData({
                jobrole: '',
                pdf: null,
                name: '',
                email: '',
                mobile: '',
                subject: '',
                message: '',
            });
        }
    };
    return (
        <div>
            <div className="container mt-5">
                <h3 className="text-center">Apply Now</h3>
                <div className="pt-5 row">
                    <div className="col-md-12">
                        <div className="col-lg-12 col-md-12 mb-lg-5">
                            <div className="contact-form careerformbg mb-5 p-4">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12 col-md-6 form-group mb-3">
                                            <label htmlFor="jobrole">Job Role</label>
                                            <select
                                                className="form-control "
                                                name="jobrole"
                                                value={formData.jobrole}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">Select Job Role</option>
                                                <option value="Sales Executive">Sales Executive</option>
                                                <option value="Marketing Executive">Marketing Executive</option>
                                                <option value="Customer Support">Customer Support</option>
                                            </select>
                                            {errors.jobrole && <small className="text-danger">{errors.jobrole}</small>}
                                        </div>
                                        <div className="col-12 col-md-6 form-group mb-3">
                                            <label htmlFor="resume">Resume</label>
                                            <input
                                                type="file"
                                                className="form-control file"
                                                name="pdf"
                                                onChange={handleFileChange}
                                                required
                                            />
                                            {errors.pdf && <small className="text-danger">{errors.pdf}</small>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-6 form-group mb-3">
                                            <label htmlFor="name">Full Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Your Name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                            />
                                            {errors.name && <small className="text-danger">{errors.name}</small>}
                                        </div>
                                        <div className="col-12 col-md-6 form-group mb-3">
                                            <label htmlFor="email">Email</label>
                                            <input
                                                type="email"
                                                className="form-control "
                                                placeholder="Your Email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                            />
                                            {errors.email && <small className="text-danger">{errors.email}</small>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-6 form-group mb-3">
                                            <label htmlFor="phone">Phone Number</label>
                                            <input
                                                type="tel"
                                                className="form-control "
                                                placeholder="Your Phone"
                                                name="mobile"
                                                value={formData.mobile}
                                                onChange={handleChange}
                                                required
                                            />
                                            {errors.mobile && <small className="text-danger">{errors.mobile}</small>}
                                        </div>
                                        <div className="col-12 col-md-6 form-group mb-3">
                                            <label htmlFor="subject">Subject</label>
                                            <input
                                                type="text"
                                                className="form-control "
                                                placeholder="Subject"
                                                name="subject"
                                                value={formData.subject}
                                                onChange={handleChange}
                                                required
                                            />
                                            {errors.subject && <small className="text-danger">{errors.subject}</small>}
                                        </div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="message" className='text-bold'>Message</label>
                                        <textarea
                                            className="form-control py-3 px-4"
                                            rows="3"
                                            placeholder="Message"
                                            name="message"
                                            value={formData.message}
                                            onChange={handleChange}
                                            required
                                        ></textarea>
                                        {errors.message && <small className="text-danger">{errors.message}</small>}
                                    </div>
                                    <div>
                                    </div>
                                    <div className='text-center'>
                                        <button className="btn submitbtn my-3  " onClick={handleSubmit} >Send Message</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Careerform;
