import React from 'react'
import { Link } from 'react-router-dom'
const Galleryhero = () => {
  return (
    <div>
       <div className="common-head">
                <div className="py-5 text-center">
                    <div className="py-5">
                        <h3 className='mb-2 text-dark'>Gallery</h3>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center bg-transparent">
                                <li className="breadcrumb-item">
                                    <Link to="/career" className='text-dark text-decoration-none'>Career</Link>
                                </li>
                                <li className="breadcrumb-item active activepage" aria-current="page">Gallery</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
    </div>
  )
}
export default Galleryhero
