import React, { useEffect, useRef } from 'react';
import { TbTruckDelivery } from "react-icons/tb";
import { BiSupport } from "react-icons/bi";
import { FaCheckCircle } from "react-icons/fa";
const Bestfe = () => {
    const fastDeliveryRef = useRef(null);
    const supportRef = useRef(null);
    const satisfactionRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            animateIfVisible(fastDeliveryRef);
            animateIfVisible(supportRef);
            animateIfVisible(satisfactionRef);
        };

        const animateIfVisible = (ref) => {
            if (ref.current) {
                const elementPosition = ref.current.getBoundingClientRect().top;
                const screenPosition = window.innerHeight / 1.3;

                if (elementPosition < screenPosition) {
                    ref.current.classList.add('visible');
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Trigger once on mount to check initial visibility

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            <div className='besfrerow'>
                <div className='container py-lg-3'>
                    <div className='row pt-5'>
                        <div className='col-lg-4 col-md-12 slide-in-left bestfecol' ref={fastDeliveryRef}>
                            <div className='row bestferow'>
                                <div className='col-lg-3 '>
                                    <TbTruckDelivery className='bestfeicon' />
                                </div>
                                <div className='col-lg-9 '>
                                    <h5>Fast Delivery</h5>
                                    <p className='fs-5 bestfetext'>Swift delivery of Habit Endless Purity products.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4  col-md-12 slide-in-bottom bestfecol' ref={supportRef}>
                            <div className='row bestferow'>
                                <div className='col-lg-3 '>
                                    <BiSupport className='bestfeicon' />
                                </div>
                                <div className='col-lg-9 col-md-12 bestfecol'>
                                    <h5>24/7 Support</h5>
                                    <p className='fs-5 bestfetext'>Dedicated support available around the clock.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-12 slide-in-right bestfecol' ref={satisfactionRef}>
                            <div className='row bestferow'>
                                <div className='col-lg-3 col-md-12'>
                                    <FaCheckCircle className='bestfeicon' />
                                </div>
                                <div className='col-lg-9 col-md-12 bestfecol'>
                                    <h5>Satisfaction Guarantee</h5>
                                    <p className='fs-5 bestfetext'>Complete satisfaction guaranteed.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Bestfe;
