import React, { useEffect, useRef } from 'react';
import { FaCircleArrowRight } from "react-icons/fa6";
import linenew from '../../assests/images/linenew.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBottleWater } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Services = () => {
    const card1Ref = useRef(null);
    const card2Ref = useRef(null);
    const card3Ref = useRef(null);
    const card4Ref = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            animateIfVisible(card1Ref);
            animateIfVisible(card2Ref);
            animateIfVisible(card3Ref);
            animateIfVisible(card4Ref);
        };

        const animateIfVisible = (ref) => {
            if (ref.current) {
                const elementPosition = ref.current.getBoundingClientRect().top;
                const screenPosition = window.innerHeight / 1.3;

                if (elementPosition < screenPosition) {
                    ref.current.classList.add('visible');
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className='container mt-5 mb-5'>
            <div className='text-center pb-5'>
                <h3 className="pb-1">What We Offer</h3>
                <h5>Your Trusted Source for Premium Water Bottles</h5>
                <img src={linenew} alt="Line Image" className='text-center' width={250} />
            </div>
            <div className="card-group ">
                <div className="card shadow service-card border slide-in-left mr-2" ref={card1Ref}>
                    <div className="bottle">
                        <FontAwesomeIcon className="bottle-icon" />
                        <div className="water"></div>
                    </div>
                    <div className="card-body">
                        <h5>HOTELS & RESTAURANTS</h5>
                        <p className="card-text ">
                            Enhance guest satisfaction with Habit's premium water bottles, tailored for hospitality.
                        </p>
                    </div>
                    <div className="pb-4 px-lg-3 servicebtncontainer">
                        <Link to='/contact' className="text-center font-weight-bold text-decoration-none servicebtn">
                            Contact Us <FaCircleArrowRight className='ml-2 ' />
                        </Link>
                    </div>
                </div>
                <div className="card border shadow service-card slide-in-left mr-2" ref={card2Ref}>
                    <div className="bottle">
                        <FontAwesomeIcon  className="bottle-icon" />
                        <div className="water"></div>
                    </div>
                    <div className="card-body">
                        <h5>RESIDENTIAL AREAS</h5>
                        <p className="card-text ">
                            Ensure family wellness with Habit's eco-friendly water bottles. Perfect for daily use at home.
                        </p>
                    </div>
                    <div className="pb-4 px-lg-3 servicebtncontainer">
                        <Link to='/contact' className="text-center font-weight-bold text-decoration-none servicebtn">
                            Contact Us <FaCircleArrowRight className='ml-lg-2 ' />
                        </Link>
                    </div>
                </div>
                <div className="card shadow border service-card slide-in-right mr-2" ref={card3Ref}>
                    <div className="bottle">
                        <FontAwesomeIcon  className="bottle-icon" />
                        <div className="water"></div>
                    </div>
                    <div className="card-body">
                        <h5>WEDDING VENUES</h5>
                        <p className="card-text ">
                            Add elegance to weddings with Habit's stylish water bottles. Keep guests refreshed on your special day.
                        </p>
                    </div>
                    <div className="pb-4 px-lg-3 servicebtncontainer">
                        <Link to='/contact' className="text-center font-weight-bold text-decoration-none servicebtn">
                            Contact Us <FaCircleArrowRight className='ml-2 ' />
                        </Link>
                    </div>
                </div>
                <div className="card border shadow service-card slide-in-right" ref={card4Ref}>
                    <div className="bottle">
                        <FontAwesomeIcon  className="bottle-icon" />
                        <div className="water"></div>
                    </div>
                    <div className="card-body">
                        <h5>BUSINESS & INDUSTRIAL ZONES</h5>
                        <p className="card-text ">
                            Boost productivity with Habit's durable water bottles. Ideal for business and industrial settings.
                        </p>
                    </div>
                    <div className="pb-4 px-lg-3 servicebtncontainer">
                        <Link to='/contact' className="text-center font-weight-bold text-decoration-none servicebtn">
                            Contact Us <FaCircleArrowRight className='ml-2 ' />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;
